@import '../../styles/variables.scss';

.banner {
	flex-shrink: 0;
	border-bottom: 1px solid #5C5C5C;
	display: flex;
	justify-content: center;
	gap: 10px;
	align-items: center;
	padding: 12px 0px;
	font-size: 28px;
	line-height: 24px;
	text-align: center;
	letter-spacing: -0.02em;
	// text-transform: uppercase;
	background-color: $banner-color-dark;

	@media (max-width: 768px) {
		font-size: 14px;
		padding: 5px;
	}

	.img_wrapper {
		&>img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		@media (max-width: 768px) {
			width: 30px;
			height: 30px;
		}
	}
}

.controllers {
	// border: 1px solid $hove-el-color;
	border-radius: 5px;
	// background-color: grey;
	transition: opacity 0.2s ease 0s;
	flex-shrink: 0;
	border-bottom: 1px solid #5C5C5C;
	// height: 68px;
	align-items: center;
	padding: 12px 0px;
	font-size: 20px;
	line-height: 24px;
	letter-spacing: -0.02em;
	z-index: 2000;
	// border: 1px solid black;
	// text-transform: uppercase;
	@media (max-width: 576px) {
		padding: 5px;
		border-bottom: none;
	}
	
}

.btns {
	padding-right: 130px;
	display: flex;
	justify-content: flex-end;
	gap: 10px;
	align-items: center;
	@media (max-width: 576px) {
		height: 100%;
		padding-right: 0;
		justify-content: center;
		align-items: center;
	}
}

.steps {
	@media (max-width: 576px) {
		font-size: 12px;
	}
}