@import '../../styles/variables.scss';

.midfield_wrapper {
    display: flex;
    height: calc(100%/3);
    .midfield {
        width: 85%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 1px solid $substrate-color;
        border-bottom: 1px solid $substrate-color;
        align-items: center;
        gap: 5px;
    }
    .midfield_filters {
        width: 15%;
        border-bottom: 1px solid $substrate-color;
        padding-top: 10px;
        display: flex;
        justify-content: flex-start;
        gap: 5px;
        flex-direction: column;
        align-items: center;
    }
    .img_wrapper {
        width: 30px;
        height: 30px;
        & > img {
            width: 100%;
            height: 100%;
            object-fit: covers;
        }
    }
}