@import '../../styles/variables.scss';

.header {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 15px;
  align-items: center;
  // border-bottom: 1px solid #5C5C5C;
  @media (max-width: 768px) and (orientation: landscape) {
    display: none;
  }

  .login_locales {
    display: flex;
    gap: 30px;
    align-items: center;
  }

  .lng_switcher {
    display: flex;
    gap: 20px;
    align-items: center;
    @media (max-width: 768px) {
      // display: none;
    }
  }
  .img_wrapper {
    width: 40px;
    height: 40px;
    cursor: pointer;
    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
}

.dark {
  background-color: $bg-color;
}

.light {
  background-color: white;
}