@import '../../styles/variables.scss';

.single_team_info {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;

    .team_info {
        
        display: flex;
        flex-direction: column;
        @media (max-width: 768px) {
            border-right: none;
            display: none;
        }
    }
    .border_dark {
        border-right: 1px solid $substrate-color;
    }

    .border_light {
        border-right: 1px solid $substrate-color-light;
    }
    .active_tab {
        height: 100%;
    } 

    .tabs_section {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
    }
    .tabs_header {
        padding: 10px 10px;
        min-height: 50px;
        width: 100%;
        border-bottom: 1px solid #5C5C5C;
        display: flex;
        gap: 2px;
        justify-content: space-between;
        align-items: center;
        @media (max-width: 576px) {
            white-space: nowrap;
            overflow-x: auto;
        }
        
    }
    .tabs_header_border_dark {
        border-bottom: 1px solid $substrate-color;
    }
    .tabs_header_border_light {
        border-bottom: 1px solid $substrate-color-light;
    }
    .tabs_content_wrapper {
        flex-grow: 1;
        overflow-y: scroll;
    }
}

.tabs_header::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent;
}

