@import '../../../styles/variables.scss';

.modal_overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1000;
    .modal_content {
        width: 60%;
        height: 35%;
        margin: 0 15px;
        padding: 24px;
        background-color: white;
        z-index: 10000;
        border-radius: 10px;
        gap: 30px;
        @media (max-width: 768px) {
            width: 100%;
            padding: 15px;
            height: auto;
        }
        .close_modal {
            width: 40px;
            height: 40px;
            @media (max-width: 576px) {
                width: 20px;
                height: 30px;
            }
            & > img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .welcome {
            font-size: 28px;
            color: #000000;
            @media (max-width: 576px) {
                font-size: 20px;
            }
        }
        .modal_content_inner {
            margin-top: 50px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            @media (max-width: 576px) {
                margin-top: 10px;
                text-align: center;
            }
        }
        .modal_header {
            display: flex;
            width: 100%;
            justify-content: flex-end;
            cursor: pointer;
            
        }
        .modal_controllers {
            margin-top: 40px;
            display: flex;
            flex-direction: column;
            gap: 20px;
            justify-content: center;
            align-items: center;
            @media (max-width: 576px) {
                margin-top: 20px;
            }
        }
    }
}