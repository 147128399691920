@import '../../styles/variables';

.field {
	width: 100%;
	height: 100%;
	.field_desktop {
		display: flex;
		color: white;
		width: 100%;
		overflow-y: auto;
		height: 100%;
		@media (max-width: 576px) {
			display: none;
		}
	}

	.field_mobile {
		display: none;
		@media (max-width: 576px) {
			display: flex;
		}
	}

	.times {
		display: flex;
		flex-direction: column;
		width: 35%;
	}

	.time {
		width: 100%;
		text-align: center;
		height: 15%;
		//border-right: 1px solid #5C5C5C;
		padding-top: 10px;
	}



	.t:not(:last-child) {
		border-right: 1px solid $substrate-color;
	}










	.metric {
		width: 100%;
		height: 20%;
		display: flex;
		justify-content: center;
		align-items: center;
		border-bottom: 1px solid $substrate-color;
		border-right: 1px solid $substrate-color;
	}


	.time_name {
		border-top: 1px solid #5C5C5C;
		width: 33.3%;
		display: flex;
		justify-content: center;
		padding: 8px;
	}

	.games_header {
		display: flex;
		flex-direction: column;
		border-right: 1px solid #5C5C5C;
		border-left: 1px solid #5C5C5C;

		&>div {
			display: flex;
			justify-content: center;
			padding: 8px;
			width: 100px;
			border-bottom: 1px solid #5C5C5C;
		}
	}

	.field_wrapper {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
	}

	.field_header {
		width: 100%;
		height: 20%;
		display: flex;
	}

	.header_item {
		width: 33.3%;
		height: 100%;
		padding: 8px 20px;

		&>span {
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 18px;
			opacity: 0.7;
		}
	}

	.header_item:not(:last-child) {
		border-right: 1px solid $substrate-color;
	}

	.games {
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 18px;
		opacity: 0.7;
	}
}

.field {
	overflow-y: scroll;
	/* или auto */
	scrollbar-width: thin;
	/* или auto */
	scrollbar-color: transparent transparent;
	/* или указать цвет фона и цвет ползунка */
	position: relative;
}

.field::-webkit-scrollbar {
	width: 0px;
	/* или другая ширина ползунка */
}

.field::-webkit-scrollbar-thumb {
	background-color: transparent;
	/* или указать цвет ползунка */
}

.field::-webkit-scrollbar-track {
	background-color: transparent;
	/* или указать цвет фона */
	border-radius: 3px;
	/* или другие стили рамки */
}

.field::before {
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	width: 0px;
	/* или другая ширина ползунка */
	background-color: #fff;
	/* или указать цвет фона */
}