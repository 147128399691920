@import '../../styles/variables.scss';

.tips_container {
    width: 100%;
    height: 600px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 15px;
    overflow-y: auto;
    padding: 20px;
    .filter {
        width: 100%;
        padding: 10px;
        display: flex;
        gap: 5px;
        color: $banner-color-light;
        align-items: center;
    }
    .input {
        width: 100%;
        border-radius: 12px;
        padding: 5px;
        border-radius: 1px solid $active-el-color;
    }
    .id {
        font-size: 30px;
        color: white;
    }
    .header_title {
        color: white;
        font-size: 20px;
    }
    .tips_list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 15px;
        width: 100%;
        
    }
    .tip_item {
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 10px;
        width: 600px;
    }
    .img_wrapper {
        width: 30px;
        height: 30px;
        & > img {
            width: 80%;
            height: 80%;
            object-fit: cover;
        }
    }
    .tip_item_header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 5px;
    }
    .tip_item_body {
        display: flex;
        flex-direction: column;
        gap: 10px;
        font-size: 12px;
    }
    iframe {
        width: 100%;
        height: 300px;
        border: none;
    }
    .chapter {
        color:white;
        display: flex;
        gap: 5px;
        align-items: center;
        justify-content: flex-start;
        padding: 5px;
        width: 100%;
        &:hover {
            background: rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            cursor: pointer;
        }
    }
    .id {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: $active-el-color;
    }
    .tip_item {
        width: 100%;
        color: white;
    }
    .tips_list_items {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        gap: 5px;
        padding-left: 25px;
    }
    .img_wrapper {
        width: 40px;
        height: 40px;
        & > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .tip_header {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 10px;
    }
    .tip_description {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}
