@import '../../styles/variables.scss';

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1000; /* Подложка, чтобы затемнить фон */
    .controllers {
        position: absolute;
        display: flex;
        gap: 15px;
        left: 45%;
        top: 56%;
        transform: translate(-50%, -50%);
        align-items: center;
        z-index: 10;
    }
    .guide_element {
        position: absolute;
        // border: 1px solid $hove-el-color;
        border-radius: 5px;
        background-color: grey;
        transition: opacity 0.2s ease 0s;
    }
    .btn {
        border: none;
        padding: 5px;
        background-color: $championship-conference-league-place-color;
        cursor: pointer;
    }
    .welcome {
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
        position: absolute;
        left: 50%;
        bottom: 70%;
        transform: translate(-50%, -50%);
        border-radius: 12px;
        text-align: center;
        @media (max-width: 576px) {
            
        }

    }
    .text {
        background-color: white;
        display: flex;
        justify-content: center;
        width: 300px;
        align-items: center;
        padding: 20px;
        position: absolute;
        transform: translate(-50%, -50%);
        border-radius: 12px;
        z-index: 10; 
    }
    .steps {
        font-size: 18;
        color: white;
    }
    .triangle_left {
        width: 0;
        height: 0;
        // border-left: 15px solid transparent;
        border-right: 15px solid white;
        border-top: 15px solid transparent;
        border-bottom: 15px solid transparent;
        position: absolute;
    }
    .triangle_top {
        width: 0;
        height: 0;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-bottom: 15px solid white;
        position: absolute;
    }
    .triangle_bottom {
        width: 0;
        height: 0;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-top: 15px solid white;
        position: absolute;
    }
    .triangle_right_little {
        width: 0;
        height: 0;
        border-bottom: 10px solid transparent;
        border-left: 10px solid white;
        border-top: 10px solid transparent;
        position: absolute;
        left: -15px; /* Смещение треугольника вправо на ширину треугольника */
        top: 50%; /* Расположение по центру элемента */
        transform: translateY(-50%);
    }
    .little_text {
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        position: absolute;
        border-radius: 12px;
        z-index: 10;
        font-size: 14px; 
        white-space: nowrap;
    }
}

 