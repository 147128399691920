$bg-color: #1F1F1F;
$active-el-color: #A266F4;
$hove-el-color: #B279FF;
$main-color: #FFFFFF;
$bg-rectangle-color: #333333;

$championship-champions-league-place-color: #4E4EFD;
$championship-europa-league-place-color: #B7A503;
$championship-conference-league-place-color: #51B136;
$championship-bottom-place: #C00000;

$results-first-place: #B3891F;
$results-second-place: #867D7D;
$results-third-place: #5C3E1F;

$championship-table-team-progress-bar-dark: #181818;
$championship-table-team-progress-bar-light: #E6D4FF;

$substrate-color: #5C5C5C;
$substrate-color-light: #E1E3EA;

$text-color-light: #333333;

$banner-color-dark: #181818;
$banner-color-light: #F3F4F7;

$resizable-border-dark: #5C5C5C;
$resizable-border-light: #E1E3EA;



// .light {
// 	color: $text-color-light
// }

// .dark {
// 	color: $main-color
// }