@import '../../styles/variables';

.stat_times {
    width: 15%;
    display: flex;
    flex-direction: column;
    .header {
        height: 15%;
        width: 100%;
        padding: 10px;
        border-right: 1px solid $substrate-color;
        border-bottom: 1px solid $substrate-color;
        height: 20%;
        font-size: 14px;
    }
    .list_times {
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    }
    .times {
        width: 100%;
        border-bottom: 1px solid $substrate-color;
        border-right: 1px solid $substrate-color;
        flex-direction: row;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 20%;
    }
    .t {
        width: 33.3%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        border-right: 1px solid #313131;
        cursor: pointer;
    }
}

.active_time {
    background-color: #181818;
    width: 33.3%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    border-right: 1px solid #313131;
    cursor: pointer;
}