.mobile_field_options_selector {
    position: relative;
    .img_wrapper {
        width: 30px;
        height: 30px;
        & > img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    .header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 5px;
    }
    .metrics_list {
        position: absolute;
        top: -6px;
        padding: 10px;
        right: 45px;
        box-shadow: 0px 2px 7px rgba(31, 31, 34, 0.25);
        border-radius: 8px;
        color: white;
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 250px;
        overflow-y: auto;
        z-index: 4;
        max-height: 150px;
        overflow-y: auto;
        background: #111;
    }
    .variant {
        display: flex;
        gap: 5px;
        align-items: center;
    }
    .variant_list {
        display: flex;
        flex-direction: column;
        gap: 5px;
        width: 100%;
    }
    .sub_header {
        display: flex;
        justify-content: space-between;
        gap: 5px;
        width: 100%;
    }
    .img_wrapper {

    }
}

.metrics_list::-webkit-scrollbar {
    width: 0;
    height: 0;
    background-color: transparent;
}