@import '../../../styles/variables.scss';

.btn {
    padding: 10px 70px;
    // border: 1px solid white;
    border: none;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45px;
    cursor: pointer;
    background-color: white;
    @media (max-width: 576px) {
        padding: 10px 50px;
    }
    &:hover {
        background-color: $hove-el-color;
    }
    .img {
        width: 20px;
        height: 20px;
        & > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}