@import '../../styles/variables.scss';

.detail_news_dynamics {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .header {
        width: 100%;
        padding: 20px;
        border-bottom: 1px solid $substrate-color;
        display: flex;
    }
    .news_header {
        width: 60%;
        display: flex;
        gap: 10px;
    }
    .news_list {
        display: flex;
        // flex-direction: column;
        gap: 10px;
        padding: 10px;
        flex-wrap: wrap;
        overflow: scroll;
    }
    .team_detail_news {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .team_detail_news_info {
        padding: 20px;
        width: 100%;
        background-color: #181818;
        display: flex;
        justify-content: space-between;
    }
    .coach_info {
        display: flex;
        gap: 20px;
        align-items: center;
    }
    .img_wrapper {
        width: 40px;
        height: 40px;
        & > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .coach_info_detail {
        display: flex;
        flex-direction: column;
        height: 100%;
        font-size: 12px;
        gap: 10px;
        & > span {
            display: block;
        }
    }
    .rating {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        font-size: 12px;
    }
    .news_list_detail {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
}