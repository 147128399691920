@import '../../styles/variables.scss';

.news_wrapper {
	//border-right: 4px solid #5C5C5C;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;

	.news {
		padding: 12px 12px;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		gap: 10px;
		overflow-y: auto;
		overflow-x: hidden;
		height: 100%;
	}

	.banner {
		background: #181818;
		border-bottom: 1px solid #5C5C5C;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 17px 0px;
		font-size: 28px;
		line-height: 24px;
		text-align: center;
		letter-spacing: -0.02em;
		text-transform: uppercase;
		color: #FFFFFF;
	}
	.news_header_tags {
		width: 100%;
		border-bottom: 1px solid $substrate-color;
		display: flex;
		gap: 10px;
		font-size: 12px;
		@media (max-width: 576px) {
			display: none;
		}
	}
	.tags_list {
		width: 70%;
		
		padding: 10px;
		overflow-x: auto;
		// flex-wrap: wrap;
		display: flex;
		justify-content: flex-end;
		gap: 5px;
		@media (max-width: 576px) {
			justify-content: flex-start;
			width: 50%;
		}
	}
	.darkBorder {
		border-right: 1px solid $substrate-color;
	}
	.lightBorder {
		border-right: 1px solid $substrate-color-light;
	}
	.filters {
		width: 40%;
		display: flex;
		gap: 10px;
		padding: 10px;
		justify-content: center;
		align-items: center;
	}
	.nonews {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
.tags_list::-webkit-scrollbar {
	height: 0; /* Устанавливает высоту ползунка в 0 */
	width: 0;
}

.tags_list::-webkit-scrollbar-thumb {
  background: transparent; /* Прозрачный фон для ползунка */
}