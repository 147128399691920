.select_metric {
  white-space: nowrap;
  padding: 5px;
  gap: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  input[type="checkbox"] {
    width: 15px;
    height: 15px;
  }
  .checkbox {
    width: 15px;
    height: 15px;
  }

  &>:nth-child(1) {
    width: 5px;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    cursor: pointer;
  }

}