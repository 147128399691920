@import '../../styles/variables.scss';

.logo {
  display: flex;
  gap: 8px;
  align-items: center;
  &_img {
	min-width: 36px;
	min-height: 36px;
	& > img {
	  width: 100%;
	  height: 100%;
	  object-fit: cover;
	}
  }
  &_name {
	font-weight: 500;
	font-size: 28px;
	line-height: 24px;
	letter-spacing: -0.02em;
	  @media (max-width: 976px) {
		  font-size: 20px;
	  }
  }
}

.light {
	color: $text-color-light
}

.dark {
	color: $main-color
}
