@import '../../../../styles/variables.scss';

.tag_selector {
    width: 60%;
    position: relative;
    font-size: 14px;
    .tags_header {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        cursor: pointer;
    }
    .available_tags {
        position: absolute;
        width: 100%;
        top: 20px;
        left: 0;
        display: flex;
        flex-direction: column;
        gap: 5px;
        padding: 5px;
        background: #181818;
        box-shadow: 0px 2px 7px rgba(31, 31, 34, 0.25);
        border-radius: 8px;
        z-index: 10;
        max-height: 300px;
        overflow-y: auto;
    }
    .tag {
        padding: 5px;
        white-space: nowrap;
        display: flex;
        gap: 5px;
        align-items: center;
        &:hover {
            background: rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            cursor: pointer;
        }
    }
    .indicator_wrapper {
        width: 10px;
        height: 10px;
    }
    .active_indicator {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: $active-el-color;
    }
}

.available_tags::-webkit-scrollbar {
    width: 0;
    background-color: transparent;
}