@import '../../styles/variables.scss';

.param_metric {
	min-height: 30px;
	width: 100%;
	display: flex;
	border-bottom: 1px solid $substrate-color;

	.chart {
		width: 30%;
		display: flex;
		padding: 12px;
		align-items: center;
		@media (max-width: 576px) {
			display: none !important;
		}
	}

	.possible_metric {
		position: relative;
		width: 100%;
		height: 12px;
		border-radius: 20px;
		background-color: transparent;
		border: 1px solid #5C5C5C;
	}

	.chart_left {
		height: 10px;
		border-radius: 20px;
		// background-color: red;
		position: absolute;
	}

	.half_border {
		height: 26px;
		width: 5px;
		border: 1px solid #5C5C5C;
		background-color: #181818;
		position: absolute;
		border-radius: 20px;
		left: 50%;
		top: -70%;
	}

	.param_metric_stats {
		display: flex;
		width: 40%;
		text-align: center;
		border-right: 1px solid $substrate-color;
		border-left: 1px solid $substrate-color;
		justify-content: space-between;
		@media (max-width: 576px) {
			width: 100%;
			border-left: none;
		}
	}

	.param_item {
		padding: 19px 12px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: 400;
		font-size: 14px;
		line-height: 16px;
		color: white;
		width: 33.3%;
		cursor: pointer;
		@media (max-width: 576px) {
			padding: 10px;
		}
	}


	.tooltip {
		position: absolute;

		background-color: white;
		border-radius: 10px;
		color: $active-el-color;
		padding: 5px;
		z-index: 100;
	}

	.param_item:not(:last-child) {
		border-right: 1px solid $substrate-color;
	}

	.param_item_tooltiped {
		padding: 19px 12px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: 400;
		font-size: 14px;
		line-height: 16px;
		color: white;
		width: 33.3%;
		position: relative;
		border-right: 1px solid $substrate-color;
		cursor: pointer;
		@media (max-width: 576px) {
			padding: 10px;
		}
	}

	
}