@import './src/styles/variables';

.team_sparkline_container {
	display: flex;
	flex-direction: column;
	cursor: pointer;

	.team_spark_line_wrapper {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		border-bottom: 1px solid rgb(92, 92, 92);

		&:hover {
			background: rgba(255, 255, 255, 0.1);
			border-radius: 4px;
		}

		.team_number {
			width: 30px;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 10px;
		}
		.td_coach_wrapper {
			width: 90px;
			height: 60px;
			padding: 10px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.team_logo_wrapper {
			width: 90px;
			height: 50px;
			padding: 10px;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.team_logo {
			width: 40px;
			height: 40px;
			&>img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.coach_wrapper {
			background-color: $bg-rectangle-color;
			width: 45px;
			height: 45px;
			border-radius: 5px;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
		}
		.line_chart_wrapper {
			width: 250px;
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 10px;
		}
		.last_candle_pos_wrapper {
			width: 60px;
			display: flex;
			justify-content: center;
		}
		.team_info {
			width: 130px;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 10px;
			padding: 10px;
			@media (max-width: 576px) {
				gap: 5px;
				font-size: 12px;
			}
		}
		.team_info_dop {
			display: flex;
			gap: 10px;
			align-items: center;
		}

		.team_name,
		.points {
			font-size: 14px;
		}

		.team_name {
			min-width: 110px;
			text-align: left;
		}

		.team_coach {
			width: 40px;
			height: 40px;
			@media (max-width: 576px) {
				width: 30px;
				height: 30px;
			}
			&>img {
				border-radius: 50%;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.cups_list {
			display: flex;
			gap: 10px;
			align-items: center;
			width: 130px;
		}

	}

	.last_games {
		width: 340px;
		display: flex;
		gap: 10px;
		padding: 10px;

		@media (max-width:1200px) {
			display: none;
		}
	}
	.border {
		width: 2px;
		background:rgb(92, 92, 92);
	}
	.next_game {
		padding: 10px;
		width: 135px;
		display: flex;
		justify-content: center;
	}
}




.collapsible {
	max-height: 0;
	overflow: hidden;
	transition: 0.3s;
	color: #282D3C;
}

.last_games_mobile {
	overflow: hidden;
	display: flex;
	justify-content: space-between;
	gap: 10px;
	padding-top: 10px;
	overflow-x: auto;
}

.last_games_mobile::-webkit-scrollbar {
	width: 0;
	background: transparent;
}
.light {
	color: $text-color-light
}

.dark {
	color: $main-color
}