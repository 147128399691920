.arrow {
    width: 0;
}

.arrow_down {
    width: 0;
    height: 12px;
    border: 1px solid #C00000;
    position: relative;
    // transform: rotate(180deg);
}
.arrow_down::after {
    content: '';
    position: absolute;
    top: 6px;
    left: -4px;
    width: 5px;
    height: 5px;
    border-bottom: 3px solid #C00000;
    border-right: 3px solid #C00000;
    transform: rotate(45deg);
}
.arrow_up {
    width: 0;
    height: 12px;
    border: 1px solid #51B136;
    position: relative;
    transform: rotate(180deg);
}
.arrow_up::after {
    content: '';
    position: absolute;
    top: 6px;
    left: -4px;
    width: 5px;
    height: 5px;
    border-bottom: 3px solid #51B136;
    border-right: 3px solid #51B136;
    transform: rotate(45deg);
}