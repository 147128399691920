@import '../../styles/variables.scss';

.playoff_grid {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    // gap: 10px;
    .playoff_header {
        width: 1200px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $substrate-color;
        padding-bottom: 10px;
    }
    .stage {
        border: none;
        background-color: transparent;
        color: white;
        font-size: 20px;
        padding: 10px;
        &:hover {
            background-color: $active-el-color;
            cursor: pointer;
        }
    }
}