@import '../../styles/variables.scss';

.calendar {
  display: flex;
  width: 100%;
  height: 100%;
  .sidebar {
    width: 13%;
    height: 100%;
    border-right: 1px solid #5C5C5C;
    @media (max-width: 576px) {
      width: 20%;
    }
  }
  .calendar_wrapper {
    width: 100%;
    height: 100%;
    padding: 10px;
    display: flex;
    gap: 20px;
    overflow-x: auto;
    // align-items: center;
    // overflow-y: scroll;
    // padding-top: 120px;
  }
  .calendar_matches {
    display: flex;
    gap: 30px;
  }
  .calendar_imgs {
    display: flex;
    flex-direction: column;
    gap: 90px;
    height: 100%;
    justify-content: center;
  }
  .calendar_img_wrapper {
    width: 20px;
    height: 20px;
    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .border {
    width: 1px;
    height: 100%;
    border: 1px solid $substrate-color;
  }
}