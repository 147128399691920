.mobile_two_candle_charts_wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    .teams_selector {
        position: absolute;
        top: 40px;
        left: 10px;
        z-index: 10;
        display: flex;
        gap: 5px;
        align-items: center;
        flex-direction: column;
    }
    .img_wrapper {
        width: 40px;
        height: 40px;
        & > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .selected_team {
        display: flex;
        align-items: center;
        gap: 5px;
    }
    .menu_content {
        padding: 5px;
        left: 0;
        box-shadow: 0px 2px 7px rgba(31, 31, 34, 0.25);
        border-radius: 8px;
        color: white;
        display: flex;
        flex-direction: column;
        gap: 5px;
        width: 100%;
        z-index: 4;
        background: #111;
        &:hover {
            background: rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            cursor: pointer;
        }
    }
}