.calendar_game_item {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	gap: 5px;
	height: 100%;
	padding: 2px;

	.goals {
		display: flex;
		gap: 5px;
		min-height: 80px;
		position: relative;
		align-items: flex-start;
	}

	.goals_detail {
		display: flex;
		flex-direction: column;
		gap: 5px;
	}

	.dates {
		display: flex;
		flex-direction: column;
		gap: 10px;
		font-weight: 400;
		font-size: 8px;
		line-height: 12px;
		color: #EEEEEE;
	}

	.img_wrapper {
		width: 40px;
		height: 40px;

		&>img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.goal_selected {
		background: #469A1F;
		width: 8px;
		height: 8px;
		border-radius: 50%;
		position: relative;
		cursor: pointer;

		&:hover {
			border: 1px solid white;
		}
	}

	.goals_versus {
		background: #ED392F;
		position: relative;
		width: 8px;
		height: 8px;
		border-radius: 50%;
		cursor: pointer;

		&:hover {
			border: 1px solid white;
		}
	}

	.img_wrapper_chart {
		min-height: 35%;
		display: flex;
		flex-direction: column;
	}

	.green {
		background: #469A1F;
		width: 12px;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
	}

	.yellow {
		background: #B7A503;
		width: 12px;
	}

	.red {
		background: #ED392F;
		width: 12px;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
	}

	.goal_tooltip {
		position: absolute;
		top: 0px;
		left: 10px;
		background-color: #EEEEEE;
		color: #ED392F;
		z-index: 4;
		color: black;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 5px 40px;
	}
}

.future_game {
	opacity: 0.5;
	&:hover {
		background: rgba(255, 255, 255, 0.1);
		border-radius: 4px;
		cursor: pointer;
		opacity: 1;
	}
}