@import '../../../styles/variables.scss';

.item_wrapper {
    display: flex;
    gap: 10px;
    align-items: center;
    .additional_settings_item {
        display: flex;
        gap: 10px;
        color: white;
        position: relative;
        display: inline-block;
        width: 30px;
        height: 18px;
    }
    input[type="checkbox"] {
        display: none;
    }
    
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        border-radius: 34px;
        transition: 0.4s;
    }
    
    .slider:before {
        position: absolute;
        content: "";
        height: 10px;
        width: 10px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        border-radius: 50%;
        transition: 0.4s;
    }
    
    input:checked+.slider {
        background-color: $active-el-color;
    }
    
    input:checked+.slider:before {
        transform: translateX(13px);
    }
    .item_name {
        color: white;
    }
}



