@import '../../styles/variables.scss';

.championship_table {
  width: 100%;
  height: 100%;
  border-right: none;
  border-left: none;
  border-top: none;
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: row-reverse;
    
  }
  .table {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 13px 12px 22px 12px;
    width: 100%;
    overflow-y: auto;
  }
  .panel_section {
    border-left: 1px solid #5C5C5C;
    padding: 7px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    @media (max-width: 768px) {
      border-right: 1px solid $substrate-color;
      border-left: none;
    }
  }
  .info_panel {
    display: flex;
    gap: 30px;
    color: #5C5C5C;
  }
  .table_tabs {
    display: flex;
    padding: 0 15px;
    gap: 16px;
    color: #FFFFFF;
    //opacity: 0.6;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    width: 100%;
    justify-content: space-between;
    // padding: 0px;
  }
  .table_teams {
    display: flex;
    flex-direction: column;
  }
  .param_list {
    display: flex;
    gap: 25px;
    color: #5C5C5C;
    justify-content: space-between;
    @media (max-width: 1600px) {
      //&>:nth-child(1), &>:nth-child(2) {
      //  display: none;
      //}
    }
  }
  .param_list_off {
    display: none;
  }
  .layout_dropdown {
    display: block;
    //@media (max-width: 1600px) {
    //  display: block;
    //}
  }
  .tab_item {
    list-style: none;
    &:not(:last-child) {
    }
  }
}
