.goal_selected {
    background: #469A1F;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: relative;
    cursor: pointer;

    &:hover {
        border: 1px solid white;
    }
}

.goals_versus {
    background: #ED392F;
    position: relative;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
        border: 1px solid white;
    }
}

.goals_not_visible {
    opacity: 0;

}

.goal_tooltip {
    transition: 0.3s ease-in-out;
    opacity: 1;
    position: absolute;
    padding: 5px 10px;
    border-radius: 12px;
    top: 16px;
    left: 50%;
    transform: translateX(-50%);
    min-width: auto;
    white-space: nowrap;
    background-color: white;
    color: black;
    font-size: 12px;
    text-align: center;
    z-index: 5;
    position: relative;
    display: inline-block;

    &:after {
        content: '';
        position: absolute;
        top: -60%;
        left: 50%;
        transform: translateX(-50%);
        /* центрирование по горизонтали */
        border-width: 6px;
        border-style: solid;
        border-color: transparent transparent #fff transparent;
        /* цвета границ */
    }

    /* Настройка цвета и размера треугольника */
    &::after {
        border-color: transparent transparent white transparent;
        /* синий цвет */
    }

    &::after {
        border-width: 8px;
        /* увеличение размера */
    }
}