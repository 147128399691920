.education_offer {
    position: absolute;
    background-color: white;
    width: 250px;
    color: black;
    padding: 10px;
    right: 55px;
    top: 135px;
    display: flex;
    flex-direction: column;
    // gap: 5px;
    border-radius: 12px;
    @media (max-width: 576px) {
        font-size: 12px;
        top: 100px;
        width: 200px;
        right: 90px;
        z-index: 10000;
    }
    .header {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    .close {
        width: 20px;
        height: 20px;
        @media (max-width: 576px) {
            width: 10px;
            height: 10px;
        }
        cursor: pointer;
        & > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .triangle_right {
        width: 0;
        height: 0;
        position: absolute;
        border-bottom: 15px solid transparent;
        border-left: 15px solid white;
        border-top: 15px solid transparent;
        right: -13px; /* Смещение треугольника вправо на ширину треугольника */
        top: 50%; /* Расположение по центру элемента */
        transform: translateY(-50%);
        @media (max-width: 576px) {
            left: -15px;
            transform: rotate(180deg);
            top: 30%;
        }
    }
    .close_button {
            border: none;
            padding: 10px;
            color: #181818;
            text-align: center;
            font-family: Jost;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            background-color: white;
            border-radius: 8px;
            z-index: 10;
            cursor: pointer;
            &:hover {
                background: #B279FF;
                color: white;
            }
            @media (max-width: 576px) {
                font-size: 12px;
                padding: 5px;
            }

    }
}