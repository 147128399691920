.circular_progress {
    position: relative;
    // height: 50px;
    // width: 50px;
    border-radius: 50%;
    background: conic-gradient(#DC7700 3.6deg, #A266F4 0deg);
    display: flex;
    align-items: center;
    justify-content: center;
}

.circular_progress::before {
    content:'';
    position: absolute;
    height: calc(100% - 10px);
    width: calc(100% - 10px);
    border-radius: 50%;
    background-color: #1F1F1F;
}

.img_wrapper {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 50%;
    transform: translateX(-50%);
    & > img {
        width: 100%;
        height: 100%;
        object-fit: contain
    }
}