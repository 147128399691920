.restore_password_section {
    margin-top: 100px;
    margin-left: 300px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 440px;
    .restore_password_header {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .back {
        color: #A266F4;
        font-family: Jost;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        cursor: pointer;
        display: flex;
        gap: 10px;
        align-items: center;
    }
    h2 {
        color: #FFF;
        font-family: Jost;
        font-size: 48px;
        font-style: normal;
        font-weight: 400;
        line-height: 48px; /* 100% */
    }
    p {
        color: rgba(255, 255, 255, 0.70);
        font-family: Jost;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 112.5% */
    }
    input {
        border-radius: 8px;
        border: 1px solid rgba(255, 255, 255, 0.40);
        padding: 10px;
        background-color: transparent;
        color: rgba(255, 255, 255, 0.70);
              
    }
}