@import "../../styles/variables.scss";


.record_item_wrapper {
    @media (max-width: 576px) {
        display: flex;
        align-items: center;
        background: #181818;
        border-radius: 10px;
        padding: 5px;
    }
    .img_mobile_wrapper {
        display: none;
        @media (max-width: 576px) {
            display: block;
            width: 50px;
            height: 90px;
            & > img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 10px;
            }
        }
    }
    .player_record_item {
        display: flex;
        justify-content: space-between;
        width: 100%;
        border-bottom: 1px solid $substrate-color;
        position: relative;
        
        @media (max-width: 576px) {
            border-bottom: none;
            flex-direction: column;
            
        }
        
        .record_detail_player_name {
            width: 20%;
            padding: 10px;
            text-align: center;
            border-right: 1px solid $substrate-color;
            @media (max-width: 576px) {
                border-right: none;
                width: 100%;
                text-align: left;
            }
        }
        .record_details {
            display: flex;
            width: 80%;
            @media (max-width: 576px) {
                width: 100%;
            }
        }
        .record_detail_info_item {
            width: calc(100% / 4);
            padding: 10px;
            text-align: center;
            @media (max-width: 576px) {
                display: flex;
                flex-direction: column;
                gap: 10px;
                padding: 0;
            }
            & > :nth-child(1) {
                display: none;
                @media (max-width: 576px) {
                    display: block;
                    opacity: 0.5;
                }
            }
            &:not(:last-child) {
                border-right: 1px solid $substrate-color;
                @media (max-width: 576px) {
                    border-right: none;
                }
            }
            &>:last-child(1) {
                cursor: pointer;
            } 
        }
        
        .img_wrapper {
            position: absolute;
            left: 25px;
            top: -70px;
            width: 150px;
            height: 150px;
            z-index: 5;
            pointer-events: none;
            @media (max-width: 576px) {
                display: none;
            }
            & > img {
                width: 100%;
                height: 100%;
                object-fit: scale-down;
            }
        }
    }
}
