.final_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    .teams_wrapper {
        display: flex;
        gap: 10px;
        align-items: center;
    }
    .teams_marks {
        padding: 15px;
        background-color: #181818;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        height: 100%;
        justify-content: space-between;
    }
    .match {
        display: flex;
        gap: 25px;
        align-items: center;
        flex-direction: column;
        // border: 1px solid red;
        padding: 5px;
        position: relative;
    }
    .team {
        display: flex;
        align-items: center;
        gap: 10px;
        flex-direction: column;
    }
    .final {
        display: flex;
        gap: 40px;
        position: relative;
    }
    .flag {
        width: 40px;
        height: 40px;
        & > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .score {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        padding: 20px 0;
    }
}

