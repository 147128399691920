@import '../../styles/variables.scss';

.contacts {
    width: 100wv;
    height: 100vh;
    color: white;
    display: flex;
    justify-content: space-between;
    h2 {
        margin-top: 20px;
    }
    .contacts_info {
        border-right: 1px solid $substrate-color;
        width: 50%;
        padding-top: 40px;
        margin-left: 20px;
    }
    .links {
        display: flex;
        flex-direction: column;
        gap: 30px;
        margin-top: 30px;
    }
    .link_item {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
    .link_name {
        font-size: 22px;
    }
    .feedback {
        padding-top: 40px;
        margin-left: 20px; 
        width: 50%;
    }
    .feedback_info {
        display: flex;
        width: 100%;
        justify-content: center;
        margin-top: 30px;
    }
    a {
        color: white;
    }
}