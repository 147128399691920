@import '../../styles//variables.scss';

.login_btn {
  padding: 9px 16px;
  border-radius: 8px;
  border: none;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  cursor: pointer;
  &:hover {
    background: #A266F4;
    color: white;
  }
  @media (max-width: 976px) {
    padding: 4px 8px;
    font-size: 16px;
  }
}

.light {
  background-color: #A266F4;
  color: $main-color;
}

.dark {
  background-color: $main-color;
  color: #051836;
}
