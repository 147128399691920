.single_news {
    color: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;
    overflow: auto;
    .header {
        font-size: 24px;
    }
    .subheader {
        display: flex;
        gap: 20px;
        font-size: 14px;
    }
    .news_content {
        display: flex;
        flex-direction: column;
        gap: 20px;
        max-width: 80%;
    }
    .img_wrapper {
        width: 80%;
        height: 50%;
        & > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .text {
        white-space: pre-line;
    }
}