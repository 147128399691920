.cabinet {
  // padding-top: 150px;
  // padding-left: 280px;
  width: 100%;
  height: 100%;
  margin-top: 150px;
  margin-left: 200px;
  @media (max-width: 576px) {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center
  }
}