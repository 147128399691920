.select_container {
	position: relative;

	@media (max-width: 976px) {
		font-size: 14px;
	}

	.select_close_default {
		display: flex;
		gap: 10px;
		align-items: center;
		cursor: pointer;
	}

	.select_opened {
		max-height: 300px; /* Высота при открытии */
		opacity: 1; /* Видимость при открытии */
		overflow-y: auto;
		width: 200px;
		position: absolute;
		top: 35px;
		left: -20px;
		padding: 10px;
		background: #181818;
		box-shadow: 0px 2px 7px rgba(31, 31, 34, 0.25);
		border-radius: 8px;
		z-index: 10;
		display: flex;
		flex-direction: column;
		gap: 10px;
		color: white;
		visibility: visible; /* Делает элемент видимым */
		transition: max-height 0.3s ease-out, opacity 0.3s ease-out, visibility 0.3s ease-out;
	}

	.select {
		max-height: 0; /* Начальная высота для закрытого состояния */
		opacity: 0; /* Начальная прозрачность для закрытого состояния */
		overflow: hidden;
		width: 200px;
		position: absolute;
		top: 35px;
		left: -20px;
		padding: 10px;
		background: #181818;
		box-shadow: 0px 2px 7px rgba(31, 31, 34, 0.25);
		border-radius: 8px;
		z-index: 10;
		display: flex;
		flex-direction: column;
		gap: 10px;
		color: white;
		visibility: hidden; /* Скрывает элемент при закрытом состоянии */
		transition: max-height 0.3s ease-in, opacity 0.3s ease-in, visibility 0.3s ease-in;
	}

	.default_active {
		color: #A266F4;
	}

	.default_close {
		color: white;
	}

	.default_close_light_theme {
		color: #181818;
	}

	.img_wrapper {
		width: 30px;
		height: 30px;

		&>img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.link_item {
		display: flex;
		align-items: center;
		gap: 10px;
		padding: 5px;

		&:hover {
			background: rgba(255, 255, 255, 0.1);
			border-radius: 4px;
			cursor: pointer;
		}
	}

	a {
		list-style-type: none;
		color: white;
		text-decoration: none;
	}
}