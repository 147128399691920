.enter_acc {
	max-width: 320px;
	color: #FFFFFF;
	display: flex;
	flex-direction: column;
	gap: 32px;
}

.enter_acc_header {
	font-style: normal;
	font-weight: 400;
	font-size: 48px;
	line-height: 48px;
	color: #FFFFFF;
}

.enter_header {
	margin-top: 20px;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 18px;
	color: rgba(255, 255, 255, 0.7);
}

.violet {
	color: #A266F4;
	font-weight: 400;
	font-size: 16px;
	line-height: 18px;
}

.img {
	width: 220px;
	height: 44px;

	&>img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
.password_input_wrapper {
	display: flex;
	justify-content: space-between;
	padding: 12px;
	border: 1px solid rgba(255, 255, 255, 0.4);
	border-radius: 8px;
}
.password_input {
	color: #FFFFFF;
	background-color: transparent;
	border: none;
	opacity: 0.5;
	width: 70%;
}
.show_password_btn {
	background-color: transparent;
		opacity: 0.7;
		width: 20px;
		height: 100%;
		border: none;
		display: flex;
		align-items: center;
		justify-content: center;

		&>img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
}
.reg_form {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 20px;

	&>input {
		color: #FFFFFF;
		background-color: transparent;
		opacity: 0.5;
		padding: 12px 0px 14px 16px;
		border: 1px solid rgba(255, 255, 255, 0.4);
		border-radius: 8px;
		width: 100%;
	}
}