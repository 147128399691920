@import url('../../../styles/variables.scss');

.main_btn {
    border: none;
    padding: 13px 55px;
    color: #181818;
    text-align: center;
    font-family: Jost;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    background-color: white;
    border-radius: 8px;
    z-index: 10;
    @media (max-width: 576px) {
      padding: 5px;
      font-size: 10px;
    }
    cursor: pointer;
    &:hover {
        background: #B279FF;
        color: white;
      }
}