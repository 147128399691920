@import '../../styles/variables.scss';

.defence_wrapper {
    width: calc(100% /3);
    .defence_header {
        height: 20%;
        border-right: 1px solid $substrate-color;
        font-size: 14px;
        border-bottom: 1px solid $substrate-color;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .defence {
        width: 100%;
        height: 100%;
        border-right: 1px solid #5C5C5C;
        display: flex;
        gap: 30px;
        align-items: center;
        height: 80%;
        position: relative;
    }
    .active_filters {
        position: absolute;
        top: 15%;
        right: 25%;
        display: flex;
        gap: 5px;
    }
    .defence_params {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        justify-content: center;
    }
    .defence_field_elements {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        position: relative;
    }
    .team_logo_wrapper {
        display: flex;
        align-items: center;
        gap: 5px;
        position: absolute;
    }
    .img {
        width: 30px;
        height: 30px;
        & > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .corner_bottom_left {
        width: 30px;
        height: 30px;
        border-top: 1px solid #5C5C5C;
        border-right: 1px solid #5C5C5C;
        border-radius: 0 100% 0 0 ;
    }
    .goalkeeper_zone {
        width: 70px;
        height: 50%;
        border-right: 1px solid #5C5C5C;
        border-top: 1px solid #5C5C5C;
        border-bottom: 1px solid #5C5C5C;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 10px;
    }
    .little_circle {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: transparent;
        border: 6px solid #DC7700;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .corner_top_left {
        width: 30px;
        height: 30px;
        border-bottom: 1px solid #5C5C5C;
        border-right: 1px solid #5C5C5C;
        border-radius: 0 0 100% 0;
    }
    .circle {
        width: 84px;
        height: 84px;
        border-radius: 50%;
        background-color: transparent;
        border: 6px solid #DC7700;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}