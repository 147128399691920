@import '../../styles/variables.scss';

.sparks_list {
    display: flex;
    flex-direction: column;
    gap: 5px;
    border-right: 0px solid transparent;
    min-width: 75px;
    position: sticky;
    left: 0;
    height: fit-content;
    z-index: 10;
    background-color: #1F1F1F;
    .sparks_list_header {
        display: flex;
        border-bottom: 1px solid $substrate-color;
        justify-content: space-between;
        font-size: 12px;
        height: 30px;
        padding: 10px;
        position: sticky;
        left: 0;
        top: 0;
        background-color:#1F1F1F;
        display: flex;
        gap: 5px;
    }

    .team_pos {
        width: 10px;
        text-align: center;
    }
    .team_name_header {
        width: 50px;
        text-align: center;
    }
    .sparkline_item {
        padding: 10px;
        display: flex;
        gap: 5px;
        align-items: center;
        height: 55px;
        border-bottom: 1px solid $substrate-color;
    }
    .team_number {
        width: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 576px) {
            font-size: 12px;
        }
    }
    .team_logo_wrapper {
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .team_logo {
        max-width: 40px;
        max-height: 40px;
        @media (max-width: 576px) {
            max-width: 30px;
            max-height: 30px;
        }
        &>img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    } 
}



.sparks_list.scrolled {
    border-right: 1px solid $substrate-color; /* Бордер будет виден при прокрутке */
}

.dop_sparkline_info {
    display: flex;
    flex-direction: column;
    height: fit-content;
    // overflow-x: auto;
    gap: 5px;
    .team_coach_header {
        width: 60px;
        text-align: center;
    }
    .dop_spakrs_info_item {
        display: flex;
        align-items: center;
        gap: 15px;
        height: 55px;
        border-bottom: 1px solid $substrate-color;
        padding-left: 10px;
    }
    .logo_coach_container {
        width: 60px;
        display: flex;
        justify-content: center;
    }
    .coach_wrapper {
        background-color: $bg-rectangle-color;
        width: 45px;
        height: 45px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        @media (max-width: 576px) {
            width: 35px;
            height: 35px;
        }
    }
    .team_coach {
        width: 40px;
        height: 40px;
        @media (max-width: 576px) {
            width: 30px;
            height: 30px;
        }
        &>img {
            border-radius: 50%;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .team_points_header {
        width: 90px;
        text-align: center;
    }
    .border {
		width: 1px;
		background:rgb(92, 92, 92);
	}
    .line_chart_and_last_candle_pos {
        display: flex;
        gap: 10px;
        width: 150px;
        align-items: center;
    }
    .line_char_header {
        width: 150px;
        text-align: center;
    }
}

.points_info {
    display: flex;
    gap: 5px;
}



.sparks_list_header {
    display: flex;
    border-bottom: 1px solid $substrate-color;
    justify-content: space-between;
    font-size: 12px;
    height: 30px;
    padding: 10px;
    position: sticky;
    left: 0;
    top: 0;
    background-color:#1F1F1F;
}

.dop_header {
    display: flex;
    border-bottom: 1px solid $substrate-color;
    // justify-content: space-between;
    font-size: 12px;
    gap: 10px;
    height: 30px;
    padding: 10px;
    position: sticky;
    gap: 10px;
    left: 0;
    top: 0;
    background-color:#1F1F1F;
    // width: 680px;
    z-index: 9;
}




.team_info {
    width: 90px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    @media (max-width: 576px) {
        gap: 5px;
        font-size: 12px;
    }
}

.team_name,
.points {
    font-size: 12px;
}

.team_name {
    min-width: 90px;
    text-align: left;
}


.last_matches {
    display: flex;
    gap: 10px;
    width: 300px;
}
.last_games_header {
    width: 300px;
    text-align: center;
}
.next_game {
    width: 70px;
    display: flex;
    justify-content: center;
}
.next_game_header {
    width: 70px;
    text-align: center;
}


.sparkline_section_mobile::-webkit-scrollbar {
    display: none;
}