.chart_container {
	display: flex;
	flex-direction: column;
}

.canvas_container {
	height: calc(100% - 45px);
	position: relative;
	@media (max-width: 576px) {
		height: calc(100% - 35px)
	}
}

.team_img {
	position: absolute;
	top: 5px;
	left: 5px;

	.team_wrapper {
		opacity: 0.5;
		width: 50px;
		height: 50px;

		&>img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}

.news_tooltip {
	width: 300px;
	max-height: 250px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.5), 
    0 0 10px rgba(0, 0, 0, 0.5),
    0 0 10px rgba(0, 0, 0, 0.5), 
    0 0 10px rgba(0, 0, 0, 0.5);
	background-color: #181818;;
	position: absolute;
	right: 0;
	bottom: 0;
	display: none;
	flex-direction: column;
	align-items: center;
	gap: 10px;
	padding: 10px;
	color: white;
	overflow-y: auto;

	.mini_news {
		display: flex;
		gap: 5px;
		justify-content: space-between;
		width: 100%;

	}

	.news_info {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 50%;
		font-size: 12px;
	}

	.img_wrapper {
		width: 80px;
		height: 80px;

		&>img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}