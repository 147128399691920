@import '../../styles/variables.scss';

.defense_wrapper {
    height: calc(100%/3);
    display: flex;
    .defense {
        width: 85%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        border-bottom: 1px solid $substrate-color;
        border-right:  1px solid $substrate-color;
        .gate_section {
            display: flex;
            justify-content: space-between;
            height: 50%;
        }
        .corner_left {
            width: 30px;
            height: 30px;
            border-bottom: 1px solid #5C5C5C;
            border-right: 1px solid #5C5C5C;
            border-radius: 0 0 100% 0;
        }
    
        .corner_right {
            width: 30px;
            height: 30px;
            border-left: 1px solid #5C5C5C;
            border-bottom: 1px solid #5C5C5C;
            border-radius: 0 0 0 100%;
        }
        .gate {
            width: 45%;
            height: 100%;
            border-bottom: 1px solid $substrate-color;
            border-left: 1px solid $substrate-color;
            border-right: 1px solid $substrate-color;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5px;
        }
        .img_wrapper {
            width: 30px;
            height: 30px;
            & > img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .team {
            width: 45px;
            padding-top: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 5px;
        }
        .arrow {
            transform: rotate(90deg);
        }
        .circle {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5px;
        }
    }
    .defense_filters {
        width: 15%;
        height: 100%;
        border-bottom: 1px solid $substrate-color;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: center;
        gap: 5px;
        padding-top: 10px;
    }
    .img_wrapper {
        width: 30px;
        height: 30px;
        & > img {
            width: 100%;
            height: 100%;
            object-fit: covers;
        }
    }
}