@import '../../../styles/variables.scss';

.header_routing_select {
    position: relative;
    display: none;
    flex-direction: column;
    width: 100px;
    cursor: pointer;
    font-size: 16px;
    .routing_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
    }
    @media (max-width: 1100px) {
        display: block;
    }
    .selected_route {
        color: white;
    }
    .selections_variantes {
      z-index: 5;
        background: #181818;
        font-size: 16px;
        position: absolute;
        width: 100%;
        left: 0;
        top: 30px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 5px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.25), 
        0 0 10px rgba(0, 0, 0, 0.25),
        0 0 10px rgba(0, 0, 0, 0.25), 
        0 0 10px rgba(0, 0, 0, 0.25);
        & > a {
            cursor: pointer;
            display: flex;
            gap: 8px;
            align-items: center;
            text-decoration: none;
            color: white;
        }
    }
    .nav_link {
        padding: 5px;
        font-size: 16px;
        &:hover {
            background: rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            cursor: pointer;
          }
    }
    
}


.active {
    color: #A266F4!important;
  }
  
  .dark {
    color: $main-color;
    &:hover {
      color: #B279FF;
    }
  }
  
  .light {
    color: $text-color-light;
    &:hover {
      color: #B279FF;
    }
  }

