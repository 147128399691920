.btn {
    border: 1px solid #555555;
    border-radius: 8px;
    padding: 12px 30px;
    width: 360px;
    color: #555555;
    background-color: transparent;
    font-size: 16px;
    line-height: 18px;
    @media (max-width: 576px) {
        padding: 10px;
        width: 280px;
    }
    &:hover {
        background-color: #A266F4;
        color: white;
        cursor: pointer;
    }
}