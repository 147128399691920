@import '../../styles/variables.scss';

.teams_news_dinamics {
    width: 100%;
    height: 100%;
    border-right: 1px solid $substrate-color;
    display: flex;
    .controlls_section {
        width: 50px;
        height: 100%;
        border-right: 1px solid $substrate-color;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        padding: 10px;
        justify-content: flex-start;
    }
    .controlls_section_left {
        width: 50px;
        height: 100%;
        border-left: 1px solid $substrate-color;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        padding: 10px;
        justify-content: flex-end;
    }
}