@import "styles/variables";

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

body {
    // background: #1F1F1F;
    font-family: 'Jost', sans-serif;
    width: 100vw;
    width: 100dvw;
    height: 100vh;
    height: 100dvh;
    user-select: none;
    background-color: #1F1F1F;
    overflow: hidden !important;
}


// .light {
//     body: {
//         background-color: $bg-color;
//     }
// }

// .dark {
//     body: {
//         background-color: white;
//     }
// }

/*.active {*/
/*    color: #A266F4 !important;*/
/*}*/

#root {
    height: 100%;
}

path:hover {
    color:rebeccapurple;
}


/* для Chrome и Safari */
::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 7px;
}

/* для Firefox */
::-moz-scrollbar {
    width: 8px;
}

::-moz-scrollbar-track {
    background-color: #FFFFFF;
    border-radius: 7px;
}

::-moz-scrollbar-thumb {
    background-color: #FFFFFF;
    border-radius: 7px;
}

/* для Internet Explorer и Edge */
::-ms-scrollbar {
    width: 8px;
}

::-ms-scrollbar-track {
    background-color: #FFFFFF;
    border-radius: 7px;
}

::-ms-scrollbar-thumb {
    background-color: #888;
    border-radius: 7px;
}

/* отображать scroll-полосы только при наведении курсора мыши */
::-webkit-scrollbar-thumb:hover {
    background-color: #FFFFFF;
}

::-moz-scrollbar-thumb:hover {
    background-color: #FFFFFF;
}

::-ms-scrollbar-thumb:hover {
    background-color: #FFFFFF;
}

/*@media (max-width: 1400px) {*/

/*}*/
/*@media (max-width: 1200px) {*/

/*}*/
/*@media (max-width: 992px) {*/

/*}*/
/*@media (max-width: 768px) {*/

/*}*/
/*@media (max-width: 576px) {*/

/*}*/
/*@media (min-width: 576px) {*/

/*}*/
