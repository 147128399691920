@import '../../styles/variables.scss';

.team_stats_controls {
    width: 100%;
    height: 50%;
    bottom: 0;
    position: absolute;
    padding-top: 69px;
    .stats_wrapper {
        border-top: 1px solid $substrate-color;
        padding: 7px;
        
    }
}