@import '../../styles/variables.scss';

.football_field_mobile {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 555px;
    overflow-y: auto;
    .controllers_n_filters {
        width: 100%;
        border-bottom: 1px solid $substrate-color;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}


.football_field_mobile::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}