@import '../../styles/variables.scss';

.coach_card_information {
  display: flex;
  flex-direction: column;
  padding: 26px 12px 26px 12px;
  @media (max-width: 576px) {
    padding: 5px;
  }
  gap: 20px;
  .info_inner {
    display: flex;
    gap: 8px;
  }
  .img {
    background-color: $bg-rectangle-color;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 54px;
    height: 58px;
    padding: 5px;
    @media (max-width: 576px) {
      width: 40px;
      height: 40px;
    }
    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .dates {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #FFFFFF;
    @media (max-width: 576px) {
      font-size: 12px;
      justify-content: center;
    }
  }
  .coach_metrics {
    display: flex;
  }
  .single_metric {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
  }
  .color_metrcic {
    height: 12px;
    width: 60px;
  }
}