@import '../../styles//variables.scss';

.work_in_progress_wrapper {
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    .work_in_progress {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $main-color;
        flex-direction: column;
        gap: 10px;
        .img_background {
            background-color: $main-color;
            border-radius: 50%;
            width: 100px;
            height: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        & >:nth-child(2) {
            font-size: 48px;
            font-style: normal;
            font-weight: 400;
            line-height: 48px;
            text-align: center; 
        }
        &>:nth-child(3) {
            color: rgba(255, 255, 255, 0.70);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
        }
    }
}