@import '../../styles/variables.scss';

.faq {
    width: 100%;
    height: 100%;
    padding: 50px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    @media (max-width: 576px) {
        padding: 40px 20px;
    }
    .faq_header {
        display: flex;
        justify-content: flex-start;
    }
    .faq_content {
        display: flex;
        justify-content: space-between;
        @media (max-width: 576px) {
            flex-direction: column;
            gap: 30px;
        }
    }
    span {
        font-family: Jost;
        font-size: 48px;
        font-style: normal;
        font-weight: 400;
        line-height: 48px;
        color: $main-color;
        @media (max-width: 576px) {
            font-size: 24px;
        }
    }
    .question_list {
        display: flex;
        flex-direction: column;
        gap: 20px;
        max-width: 50%;
        @media (max-width: 576px) {
            max-width: 100%;
        }
    }
    
}