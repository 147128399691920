@import '../../../styles/variables.scss';

.grid {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    max-width: 100%;
    // align-items: center;
    height: calc(100% - 70px);
    position: relative;
    .half_backdrop_left {
        background-color: #181818;
        width: 50%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        opacity: 0.3;
    }
    .half_backdrop_right {
        background-color: #181818;
        width: 50%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 0;
        opacity: 0.3;
    }
    .lines {
        display: flex;
        flex-direction: column;
        // height: 100%;
        gap: 200px;
        // justify-content: space-between;
        // padding-top: 50px;
        // padding-bottom: 50px;
        
    }
    .lines_part {
        display: flex;
        flex-direction: column;
        height: 45%;
        justify-content: space-between;
    }
    
    .final {
        display: flex;
        gap: 40px;
        height: 100%;
        justify-content: flex-start;
        margin-top: 5%;
    }
    
    .final_line {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        padding-bottom: 20%;
    }
    
    .half_grid_line_to_final {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .line_container {
        height: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 50px 0 50px 0;
    }
}





