@import '../../styles/variables.scss';

.news_item {
	display: flex;
	gap: 5px;
	flex-direction: column;
	// justify-content: space-between;
	// min-height: 150px;
	// max-height: 200px;
	padding: 8px;
	display: flex;

	@media (max-width: 576px) {
		width: 100%;
		flex-direction: row;
		min-height: 50px;
	}

	&:hover {
		background: rgba(255, 255, 255, 0.1);
		border-radius: 4px;

	}

	.news_img {
		width: 180px;
		height: 100px;
		border-radius: 8px;

		&>img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			border-radius: 8px;
		}
	}

	.news_description {
		display: flex;
		flex-direction: column;
		gap: 10px;
		justify-content: space-between;
		font-size: 12px;
		line-height: 14px;
	}

	.description_wrapper {
		display: flex;
		flex-direction: column;
		gap: 10px;
	}

	.title {
		font-size: 14px;
		line-height: 18px;
		font-weight: 400;
		max-width: 180px;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		overflow: hidden;

		@media (max-width: 576px) {
			width: 100%;
		}
	}

	.source {
		display: flex;
		gap: 10px;
		transition: 0.5s;
	}

	.source_link {
		text-decoration: none;

		&:hover {
			color: #A266F4;
			border-bottom: 1px solid #A266F4;
			transition: 0.3s;
			cursor: pointer;
		}
	}
	.tags {
		font-size: 14px;
		border: 1px solid #A266F4;
		border-radius: 12px;
		padding: 5px;
	}
}

.light {
	color: $text-color-light
}

.dark {
	color: $main-color
}

.active_item {
	background: rgba(255, 255, 255, 0.1);
	border-radius: 4px;
}