@import '../../styles/variables.scss';
// DESKTOP > 768px
.panels {
    display: flex;
    //justify-content: space-between;
    width: 100%;
    height: 100%;
    @media (max-width: 768px) {
        display: none !important;
    } 
    .work_panel_left {
        border: 1px solid #5C5C5C;
        padding: 7px;
        display: flex;
        flex-direction: column;
        align-items: center;
        //justify-content: space-between;
        gap: 5px;
        border-bottom: none;
        border-left: none;
        border-top: none;
        @media (max-width: 768px) {
            display: none;
        }
    }
    .right_section_chart {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }
    .left_section {
        display: flex;
        width: 70%;
        
    }
    .chart {
        display: flex;
        justify-content: space-between;
        max-width: 100%;
        flex-shrink: 0;
        height: 50%;
        @media (max-width: 768px) {
            flex-direction: row-reverse;
        }
        @media (max-width: 576px) {
            display: none
        }
    }
    .panel_section {
        max-width: 53px;
        border-left: 1px solid #5C5C5C;
        padding: 7px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 3px;
        position: relative;
    }
    .news_section {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        flex-shrink: 1;
        flex-grow: 1;
        
        
    }
    .news_section_inner {
        flex-grow: 1;
        display: flex;
        justify-content: space-between;
        overflow-y: hidden;
    }
    .championship_table_section {
        width: 30%;
        flex-direction: column;
        flex: 1;
        // @media (max-width: 768px) {
        //     display: none !important;
        // }
    }
    .recent_games_section {
        // overflow: auto;
        flex-shrink: 1;
        flex-grow: 1;
        display: flex;
        height: 50%;
    }
    .right_section {
        //width: 20%;
        //height: 40%;
    }
    
}
// DESKTOP > 768px


//MOBILE < 768px 
.mobile {
    height: 100%;
    display: none;
    flex-direction: column;
    display: none;
    color: white;
    @media (max-width: 576px) {
        display: flex;
    }
    .panel_section_mobile {
        min-width: 53px;
        max-width: 53px;
        border-right: 1px solid #5C5C5C;
        padding: 7px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 3px;
        position: relative;
        overflow-y: auto;
        // z-index: 3;
    }
    .sparkline_mobile {
        display: flex;
        height: 100%;
        width: 100%;
    }
    @media (max-width: 768px) {
        display: block;
    }
    .championship_table_mobile {
        height: 50%;
        
    }
    .mobile_bottom_section {
        height: calc(50% - 43px);
        // @media (orientation: landscape) {
        //     display: none !important;
        // }
    }
    .loader_wrapper {
        width: 100%;
        height: 100%;
    }
    .border_banner {
        display: flex;
        flex-direction: column;
        @media (orientation: landscape) {
            display: none;
        }
    }
}

.sparkline_section_mobile {
    display: none;
    // @media (min-width: 576px) and (orientation: landscape) {
    //     // height: 100% !important;
    // }
    @media (max-width: 768px) {
        display: flex;
        overflow-y: auto;
        width: 100%;
        // gap: 5px;
        height: 100%;
        position: relative;
    }

}

.panel_section_mobile::-webkit-scrollbar {
    width: 0;
    background-color: transparent;
}


//MOBILE < 768px 












.mobile_controls {
    max-width: 53px;
    border-right: 1px solid $substrate-color;
    padding: 7px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3px;
    position: relative;
}



.mobile_bottom_section {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    overflow-y: hidden;
}

.dark {
    background-color: $bg-color;
    color: $main-color;
}

.light {
    background-color: white;
    color: $text-color-light;
}
