.single_tab {
  padding: 6px 6px;
  border: 1px solid #5C5C5C;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: white;
  background: transparent;
  cursor: pointer;
  white-space: nowrap;
}

.dark {
  border: 1px solid #5C5C5C;
  color: white;
}

.light {
  color: #333333;
  border: 1px solid #E1E3EA;
}

.active {
  background:  #A266F4;
}