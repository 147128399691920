@import '../../../styles/variables.scss';

.field_mobile_selector {
    width: 100%;
    border-right: 1px solid $substrate-color;
    font-size: 12px;
    padding: 15px;
    position: relative;
    .selector_header {
        display: flex;
        justify-content: space-between;
    }
    .selectors_list {
        position: absolute;
        top: 40px;
        padding: 5px;
        left: 0;
        box-shadow: 0px 2px 7px rgba(31, 31, 34, 0.25);
        border-radius: 8px;
        color: white;
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;
        z-index: 4;
        max-height: 200px;
        overflow-y: auto;
        background: #111;
        padding: 10px;
    }
    .selector_item {
        padding: 5px;
        &:hover {
            background: rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            cursor: pointer;
        }
    }
}