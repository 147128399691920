@import '../../styles/variables.scss';

.teams_news_dynamics {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
    border-right: 1px solid $substrate-color;
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        border-bottom: 1px solid $substrate-color;
    }
    .teams_list {
        display: flex;
        flex-direction: column;
        overflow: scroll;
        height: 50%;
    }
    .single_team {
        padding: 10px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 10px;
        align-items: center;
        border-bottom: 1px solid $substrate-color;
    }
    .img_wrapper {
        width: 40px;
        height: 40px;
        & > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .team_name {
        width: 150px;
    }
    .player_stat_wrapper {
        width: 100%;
    }
    .player_stat_header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        & >:nth-child(1), :nth-child(2) {
            padding: 20px;
        }
        & >:nth-child(1) {
            border-right: 1px solid $substrate-color;
            width: 50%;
        }
        border-bottom: 1px solid $substrate-color;
    }
    .player_stat {
        display: flex;
        justify-content: space-between;
    }
    .player_img_card {
        width: 50%;
        padding: 20px;
        border-right: 1px solid $substrate-color;
    }
    .img_wrapper_player {
        width: 80%;
        height: 80%;
        & > img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    .player_params {
        display: flex;
        flex-direction: column;
        width: 50%;
    }
    .single_param {
        display: flex;
        border-bottom: 1px solid $substrate-color;
        &>:nth-child(1), :nth-child(2) {
            padding: 20px;
        }
        &>:nth-child(1) {
            border-right: 1px solid $substrate-color;
        }
    }
}