.ticker_wrapper {
  background: #000000;
  height: 24px;
  display: flex;
  align-items: center;
  width: 100%;
  @media (max-width: 576px) {
    height: 16px;
  }
  //position: relative;
  .ticker {
    width: 100%;
    display: flex;
    gap: 25px;
    overflow: hidden;
    white-space: nowrap;
    .recent_game {
      font-weight: 400;
      //width: 450px;
      font-size: 16px;
      line-height: 18px;
      color: #FFFFFF;
      display: flex;
      gap: 5px;
      align-items: center;
      @media (max-width: 576px) {
        font-size: 12px;
      }
    }
    .img_wrapper {
      width: 18px;
      height: 18px;
      @media (max-width: 576px) {
        width: 12px;
        height: 12px;
      }
      & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
