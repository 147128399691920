@import "./src/styles/variables";

.sponsors {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;

	.sponsors_header {
		width: 100%;
		border-bottom: 1px solid $substrate-color;
		color: $main-color;
		display: flex;
		justify-content: space-between;
		position: sticky;
		top: 0;
		@media (min-width: 320px) and (max-width: 576px) {
			display: none;
		}

		.header_item {
			width: 25%;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 12px;
		}

		.header_item:not(:last-child) {
			border-right: 1px solid $substrate-color;
		}
	}
	.sponsors_list {
		flex-grow: 1;
		overflow-y: auto;
		display: flex;
		flex-direction: column;
		@media (min-width: 320px) and (max-width: 576px) {
			padding: 10px;
			gap: 20px;
		}
	}
	.sponsor_item {
		display: flex;
		width: 100%;

		@media (min-width: 320px) and (max-width: 576px) {
			padding: 10px;
			background: #181818;
			flex-direction: column;
			border-radius: 10px;
		}
	}
	.sponsor_info {
		width: 25%;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 12px;
		color: white;
		border-right: 1px solid $substrate-color;
		border-bottom: 1px solid $substrate-color;
		@media (min-width: 320px) and (max-width: 576px) {
			border-bottom: none;
			border-right: none;
			display: none;
		}
	}
	.title_mobile {
		display: none;
		@media (min-width: 320px) and (max-width: 576px) {
			display: flex;
			justify-content: start;
			width: 100%;
			padding: 12px;
			color: white;
		}
	}
	.mobile_border {
		display: none;
		@media (min-width: 320px) and (max-width: 576px) {
			display: block;
			width: 100%;
			height: 2px;
			background: #1F1F1F;
		}
		
	}
	
	.sponsor_info_detail {
		display: flex;
		width: 75%;
		& > :nth-child(2) {
			display: none;
		}
		@media (min-width: 320px) and (max-width: 576px) {
			width: 100%;
			font-size: 12px;
			gap: 5px;
			flex-wrap: wrap;
			& > :nth-child(1) {
				display: none;
			}
			& > :nth-child(2) {
				display: flex;
			} 
		}
	}
	.sponsor_info_dop {
		width: calc(100%/3);
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 12px;
		color: white;
		border-bottom: 1px solid $substrate-color;
		white-space: nowrap;
		& > span {
			display: block;
		}
		& > :nth-child(1) {
			display: none;
		}
		@media (min-width: 320px) and (max-width: 576px) {
			border-bottom: none;
			display: flex;
			flex-direction: column;
			gap: 10px;
			padding: 10px 0;
			& > :nth-child(1) {
				opacity: 0.5;
				display: block;
			}
		}
	}
	.sponsor_info_dop:not(:last-child) {
		border-right: 1px solid $substrate-color;
		@media (min-width: 320px) and (max-width: 576px) {
			border-right: none;
		}
	}
	.sponsors_list::-webkit-scrollbar {
		width: 0;
		/* Убираем ползунок скролла в Webkit (Chrome, Safari) */
	}
	
	.sponsors_list {
		scrollbar-width: none;
		/* Убираем ползунок скролла в Firefox */
	}
	
}


