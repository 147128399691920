.calendar_sidebar {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    padding: 10px;
    .scale_wrapper {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        justify-content: center;
        & > span {
            font-size: 12px;
        }
    }
    .scale {
        width: 12px;
        height: 100%;
        border-radius: 12px;
        background-color: #181818;
        border: 1px solid #5C5C5C;
        position: relative;
        @media (max-width: 576px) {
            width: 8px;
        }
    }
    .img {
        position: absolute;
        left: -40px;
        top: -15px;
        width: 35px;
        height: 35px;
        z-index: 3;
        @media (max-width: 576px) {
            width: 20px;
            height: 20px;
            left: -25px;
            top: -8px;
        }
        & > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .sidebar_parameters {
        display: flex;
        flex-direction: column;
        font-size: 12px;
        justify-content: space-between;
    }
    .first_team_force_inder {
        border-radius: 12px;
        width: 20px;
        left: -5px;
        height: 5px;
        position: absolute;
        background-color: #A266F4;
        cursor: pointer;
        width: 20px;
    }
    .second_team_force_inder {
        border-radius: 12px;
        width: 20px;
        left: -5px;
        height: 5px;
        position: absolute;
        background-color: #DC7700; 
        @media (max-width: 576px) {
            width: 12px;
            left: -4px;
        }
    }
    .img_second {
        position: absolute;
        left: 23px;
        top: -15px;
        width: 35px;
        height: 35px;
        z-index: 3;
        @media (max-width: 576px) {
            width: 20px;
            height: 20px;
            top: -9px;
        }
        & > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        } 
    }
    .first_procents {
        font-size: 12px;
        position: absolute;
        left: 23px;
        top: -3px;
        @media (max-width: 576px) {
            left: 15px;
            font-size: 10px;
        }
    }
    .second_procents {
        font-size: 12px;
        position: absolute;
        left: -33px;
        top: -3px;
        @media (max-width: 576px) {
            font-size: 10px;
            left: -25px;
        }
    }
}