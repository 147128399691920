.tooltip {
    min-width: 300px;
    display: none;
    font-size: 12px;
    color: white;
    background-color: #343434;
    z-index: 3;
    border-radius: 12px;
    transition: 0.5s;
    padding: 10px;
    text-align: center;
    position: absolute;
    text-align: left;
}

.visible {
    display: block;
    animation: shows .3s ease-out forwards;
}

.tooltip::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #343434 transparent;
}

@keyframes shows {
    from {
        opacity: 0;
        transform: translate3d(-1%, 0, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(-1%, -10px, 0);
    }
}