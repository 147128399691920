.previous_sparkline_game {
	display: flex;
	gap: 10px;
	align-items: center;
	color: white;

	@media (max-width: 1250px) {
		// display: none;
	}

	@media (max-width: 576px) {
		font-size: 12px;
	}

	//min-width: 140px;
	.game_info {
		display: flex;
		flex-direction: column;
		gap: 5px;
		align-items: center;
		justify-content: center;
	}

	.game_final_score {
		display: flex;
		gap: 10px;

		&:nth-child(1) {
			font-size: 16px;
		}

		&:nth-child(2) {
			font-size: 14px;
		}
	}

	.win {
		color: #51B136;
	}

	.lose {
		color: #C00000;
	}

	.draw {
		color: #B7A503;
	}

	.rival_team_img {
		width: 40px;
		height: 40px;

		@media (max-width: 576px) {
			width: 30px;
			height: 30px;
		}

		&>img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}