@import '../../../styles/variables.scss';

.games_n_times_select {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 8px;
    width: 25%;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: white;
    border-bottom: 1px solid $substrate-color;
    border-right: 1px solid $substrate-color;
    cursor: pointer;
    
    @media (max-width: 576px) {
        width: 50%;
        border-bottom: none;
    }
    
    .chart_select {
        position: absolute;
        top: 40px;
        
        left: 0;
        box-shadow: 0px 2px 7px rgba(31, 31, 34, 0.25);
        border-radius: 8px;
        color: white;
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;
        z-index: 4;
        max-height: 0; /* Начальное состояние: скрыто */
        overflow: hidden; /* Скрываем содержимое при закрытом меню */
        opacity: 0; /* Начальная прозрачность */
        transition: max-height 0.3s ease-out, opacity 0.3s ease-out; /* Плавные переходы */
        background: #111;
    }
    
    .open {
        max-height: 200px; /* Конечная максимальная высота при открытии */
        opacity: 1; /* Видимость при открытии */
    }

    .selectors_list {
        /* Можно добавить дополнительные стили для списка, если нужно */
        padding: 5px;
    }
    
    .select_metric {
        white-space: nowrap;
        padding: 5px;
        display: flex;
        align-items: center;
        gap: 10px;
        
        & >:nth-child(1) {
            width: 5px;
        }
        
        &:hover {
            background: rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            cursor: pointer;
        }
    }
    
    .colorDark {
        color: white;
    }
    
    .colorLight {
        color: #333333;
    }
    
    .disabled {
        color: gray;
    }
}
