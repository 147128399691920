@import '../../styles/variables.scss';

.panels {
	display: flex;
	//justify-content: space-between;
	width: 100%;
	height: 100%;

	.left_section {
		display: flex;
		width: 70%;
		min-width: 0;
	}

	.right_section {
		//width: 20%;
		//height: 40%;
	}

	.work_panel_left {
		border: 1px solid #5C5C5C;
		padding: 7px;
		display: flex;
		flex-direction: column;
		align-items: center;
		//justify-content: space-between;
		gap: 5px;
		border-bottom: none;
		border-left: none;
		border-top: none;
	}

	.right_section_chart {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
	}

	.panel_section {
		max-width: 53px;
		border-left: 1px solid #5C5C5C;
		padding: 7px;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 3px;
		position: relative;
	}
}

.panel_section {
	max-width: 53px;
	border-left: 1px solid #5C5C5C;
	padding: 7px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 3px;
	position: relative;
}

.test_btn {
	border: 1px solid white;
	background-color: transparent;
	padding: 10px;
	color: white;
}

.chart {
	display: flex;
	justify-content: space-between;
	max-width: 100%;
	flex-shrink: 0;
	// height: 100%;
	//position: relative;
}

.row_resizable_border {
	border: 2px solid $resizable-border-dark;
}

.col_resizable_border {
	border: 2px solid $resizable-border-dark;
}

.resizable_border_dark {
	border: 2px solid $resizable-border-dark;
}

.resizable_border_light {
	border: 2px solid $resizable-border-light;
}


.championship_table_section {
	width: 30%;
	flex-direction: column;
	flex: 1;
}

.recent_games_section {
	overflow: auto;
	flex-shrink: 1;
	flex-grow: 1;
	display: flex;
}



.news_section {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	flex-shrink: 1;
	flex-grow: 1;
	flex-direction: column;
	// height: 100%;
}

.banner {
	flex-shrink: 0;
	border-bottom: 1px solid #5C5C5C;
	display: flex;
	justify-content: center;
	gap: 10px;
	align-items: center;
	padding: 17px 0px;
	font-size: 28px;
	line-height: 24px;
	text-align: center;
	letter-spacing: -0.02em;
	// text-transform: uppercase;
	background-color: $banner-color-dark;
}

.img_wrapper {
	width: 30px;
	height: 30px;

	&>img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.news_section_inner {
	flex-grow: 1;
	display: flex;
	justify-content: space-between;
	overflow-y: hidden;
	// height: 100%;
}

.dark {
	background-color: $bg-color;
	color: $main-color;
}

.light {
	background-color: white;
	color: $text-color-light;
}

.bg {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	pointer-events: none;

	&>img {
		background-size: cover;
		background-repeat: no-repeat;
		background-attachment: fixed;
		width: 100%;
		height: 100%;
	}
}

.main {
	height: calc(100% - 50px - 24px);
	@media (max-width: 576px) {
		height: calc(100% - 50px - 16px);
	}
	@media (max-width: 768px) and (orientation: landscape) {
		height: calc(100% - 24px);
	}

}