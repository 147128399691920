@import "src/styles/variables";

.results_table {
	display: flex;
	justify-content: space-between;
	width: 100%;
	height: 100%;
	@media (max-width: 576px) {
		flex-direction: column;
		overflow: auto;
	}
	.club_coach_section {
		width: 25%;
		@media (max-width: 576px) {
			width: 100%;
			display: flex;
			border-bottom: 1px solid $substrate-color;
			justify-content: center;
			gap: 10px;
		}
	}
	.table_header_list {
		width: 100%;
		display: flex;
		justify-content: space-between;
		
		height: 50px;
		@media (max-width: 576px) {
			display: none;
		}
	}
	.table_header_list_dark {
		border-bottom: 1px solid $substrate-color;
		border-left: 1px solid $substrate-color;
	}

	.table_header_list_light {
		border-bottom: 1px solid $substrate-color-light;
		border-left: 1px solid $substrate-color-light
	}

	.table_header {
		width: 100%;
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid $substrate-color;
		height: calc(100% /4);
		@media (max-width: 576px) {
			background: #181818;
			padding: 10px;
			border-radius: 10px;
			border-bottom: none;
			flex-direction: column;
			height: auto;
		}
	}
	.season_parameter_header {
		padding: 15px 10px;
		color: white;
		display: flex;
		justify-content: center;
		align-items: center;
		width: calc(100% / 7);
		font-size: 14px;
		text-align: center;
	}
	.season_parameter_title {
		padding: 15px 10px;
		color: white;
		display: flex;
		justify-content: center;
		align-items: center;
		width: calc(100%/7);
		font-size: 14px;
		text-align: center;
		border-right: 1px solid $substrate-color;
		& > :nth-child(1) {
			display: none;
			opacity: 0.5;
			@media (max-width: 576px) {
				display: block;
			}
		}
		@media (max-width: 576px) {
			border-right: none;
			width: 100%;
			gap: 10px;
		}
	}
	
	.seasons_params_info {
		width: calc(100% - (100%/7));
		display: flex;
		@media (max-width: 576px) {
			justify-content: space-around;
			width: 100%;
			flex-wrap: wrap;
			gap: 5px;
		}
	}
	.season_parameter {
		padding: 15px 10px;
		color: white;
		display: flex;
		justify-content: center;
		align-items: center;
		width: calc(100%/4);
		font-size: 14px;
		text-align: center;
		@media (max-width: 576px) {
			flex-direction: column;
			gap: 10px;
			justify-content: space-between;
		}
		& > :nth-child(1) {
			display: none;
			@media (max-width: 576px) {
				opacity: 0.5;
				display: block;
			}
		}
	}

	.season_parameter:not(:last-child) {
		border-right: 1px solid $substrate-color;
		@media (max-width: 576px) {
			border-right: none;
		}
	}

	.season_parameter_header:not(:last-child) {
		border-right: 1px solid $substrate-color;
		@media (max-width: 576px) {
			border-right: none;
		}
	}

	.table_info {
		height: 100%;
		width: 75%;

		@media (max-width: 1400px) {
			font-size: 12px;
		}
		@media (max-width: 576px) {
			width: 100%;
			display: inline-block
		}
	}

	.season_parameter_price_place {
		width: 35px;
		height: 35px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
	}

	.results {
		height: 100%;
		overflow: auto;
		// padding-bottom: 85px;
		border-left: 1px solid $substrate-color;
		@media (max-width: 576px) {
			display: flex;
			flex-direction: column;
			gap: 10px;
			padding: 10px;
			overflow: visible;
			border-left: none;
		}
	}

	.season_parameter_1st_prize {
		border: 3px solid $results-first-place;
	}

	.season_parameter_2st_prize {
		border: 3px solid $results-second-place;
	}

	.season_parameter_3st_prize {
		border: 3px solid $results-third-place;
	}
}

.results {
	overflow-y: scroll;
	/* или auto */
	scrollbar-width: thin;
	/* или auto */
	scrollbar-color: transparent transparent;
	/* или указать цвет фона и цвет ползунка */
	position: relative;
}

.results::-webkit-scrollbar {
	width: 0px;
	/* или другая ширина ползунка */
}

.results::-webkit-scrollbar-thumb {
	background-color: transparent;
	/* или указать цвет ползунка */
}

.results::-webkit-scrollbar-track {
	background-color: transparent;
	/* или указать цвет фона */
	border-radius: 3px;
	/* или другие стили рамки */
}

.results::before {
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	width: 0px;
	/* или другая ширина ползунка */
	background-color: #fff;
	/* или указать цвет фона */
}