.recent_results {
	width: 100%;
	height: 100%;
	border: 1px solid #5C5C5C;
	border-right: none;
	border-top: none;
	border-left: none;
	display: flex;
	justify-content: space-between;
	border-bottom: none;
	flex-direction: column;
	@media (max-width: 576px) {
		overflow: auto;
	}

	.results_list {
		width: 100%;
		// padding: 13px 12px 22px 12px;
		display: flex;
		flex-direction: column;
		gap: 20px;
		overflow-y: auto;

		@media (max-width: 1600px) {
			padding: 5px;
		}
	}

	.results_wrapper {
		display: flex;
		flex-direction: column;
		gap: 5px;
		width: 100%;
		padding: 10px;
	}

	.result_header {
		padding: 5px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}