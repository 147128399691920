@import '../../../styles/variables.scss';

.selected_params {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    cursor: pointer;
    @media (max-width: 576px) {
        flex-direction: row-reverse;
    }
    .img_wrapper {
        width: 30px;
        height: 30px;
        & > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .select_teams {
        display: flex;
        gap: 5px;
        align-items: center;
    }
}

.football_field_selection {
    position: absolute;
    bottom: 30px;
    left: 0px;
    background-color: #111;
    // border-radius: 8px;
    box-shadow: 0px 2px 7px 0px rgba(31, 31, 34, 0.25);
    width: 100%;
    overflow-y: auto;
    color: $main-color;
    z-index: 5;
    padding: 10px;
    font-size: 16px;
    width: 250px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 250px;
    overflow: scroll;
    @media (max-width: 576px) {
        left: -50px;
        top: 60px;
        width: auto;
        padding: 5px;
        white-space: nowrap;
    }
    .wrapper {
        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: center;
        padding: 5px;
        &:hover {
            background: rgba(255, 255, 255, 0.1);
            border-radius: 4px;
        }
    }
    .params_lists {
        display: flex;
        gap: 10px;
        align-items: center;
        @media (max-width: 576px) {
            font-size: 12px;
        }
    }
    .list_items {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
    .checkbox_param {
        display: flex;
        gap: 5px;
        align-items: center;
    }
    .img_wrapper {
        width: 30px;
        height: 30px;
        @media (max-width: 576px) {
            width: 20px;
            height: 20px;
        }
        & > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}



