.restore_password_modal_overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .74);
    top: 0;
    left: 0;
    z-index: 4;
    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 1000;

    .restore_password_moda_content {
        background-color: #1d1c1c;
        padding: 10px;
        padding: 40px;
        border-radius: 12px;
    }

    .modal_header {
        display: flex;
        justify-content: flex-start;
        font-size: 20px;
        color: white;
    }

    .restore_password_from {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .field {
        display: flex;
        flex-direction: column;
        gap: 8px;
        color: white;

        &>label {
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #FFFFFF;
            opacity: 0.5;
        }

        &>input {
            border: 1px solid rgba(255, 255, 255, 0.4);
            border-radius: 8px;
            background-color: rgba(255, 255, 255, 0);
            width: 330px;
            padding: 16px;
            color: rgba(255, 255, 255, 0.4);
            outline: none;

            @media (max-width: 1200px) {
                width: 250px;
            }

            &::placeholder {
                color: #FFFFFF;
                font-weight: 400;
                font-size: 16px;
                line-height: 18px;
            }
        }
    }

    .btn_container {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 40px;
    }
}