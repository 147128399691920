.team_uniform_item {
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  align-items: center;
  width: 30%;
  @media (min-width: 320px) and (max-width: 576px) {
    height: 100%;
  }
  .img_container {
    background: #181818;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 185px;
    height: 224px;
    @media (min-width: 320px) and (max-width: 576px) {
      padding: 10px;
      width: 100%;
      height: 100%;
    }
  }
  .img_wrapper {
    width: 100%;
    height: 160px;
    @media (min-width: 320px) and (max-width: 576px) {
      width: 85px;
      height: 85px;
    }
    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .uniform_type {
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: white;
  }
}