@import '../../styles/variables.scss';

.team_card_logo {
	display: flex;
	gap: 10px;

	padding: 10px;
	align-items: center;
	font-size: 14px;

	@media (max-width: 576px) {
		border-bottom: none;
		padding: 5px;
	}
}

.border_dark {
	border-bottom: 1px solid $substrate-color;
}

.border_light {
	border-bottom: 1px solid $substrate-color-light;
}

.logo {
	max-width: 50px;
	max-height: 50px;

	@media (max-width: 576px) {
		width: 30px;
		height: 30px;
	}

	&>img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.club_description {
	color: white;
	max-width: 130px;
	font-size: 14px;

	@media (max-width: 576px) {
		&> :nth-child(2) {
			display: none;
		}

		font-size: 12px;
	}
}