@import '../../styles/variables.scss';

.plays {
    display: flex;
    flex-direction: column;
    width: 15%;
    height: 100%;

    .plays_header {
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 20%;
        border-bottom: 1px solid $substrate-color;
        border-right: 1px solid $substrate-color;
        font-size: 14px;
    }

    .games_selector {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
    }

    .last_games_container {
        height: 80%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }

    .last_match {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 10px;
        padding-left: 10px;
        border-right: 1px solid $substrate-color;
        border-bottom: 1px solid $substrate-color;
        height: calc(100%/6);
    }

    .img_wrapper {
        width: 18px;
        height: 18px;

        &>img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .score {
        display: flex;
        width: 30%;
        justify-content: space-between;
    }

    .metric {
        width: 100%;
        height: calc(100%/6);
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid $substrate-color;
        border-right: 1px solid $substrate-color;
        cursor: pointer;
    }
}

.active_metric {
    background-color: #181818;
    width: 100%;
    height: calc(100%/6);
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid $substrate-color;
    border-right: 1px solid $substrate-color;
    cursor: pointer;
    color: white !important;
}




.disable_metric {
    // background-color: #181818;
    width: 100%;
    height: calc(100%/6);
    display: flex;
    justify-content: center;
    align-items: center;
    color: grey;
    border-bottom: 1px solid $substrate-color;
    border-right: 1px solid $substrate-color;
    cursor: pointer;
}

