@import '../../styles/variables.scss';

.field {
    width: 600px;
    height: 800px;
    border: 1px solid gray;
    background-color: #228b22;
    position: relative;
  
    &::after {
      content: '';
      width: 100%;
      position: absolute;
      height: 2px;
      top: 50%;
      left: 0;
      right: 0;
      margin: 0 auto;
      background-color: white;
      opacity: 0.5;
      z-index: 2; // Цвет центральной линии
    }
  
    &::before {
      content: '';
      width: 250px; // Диаметр центрального круга
      height: 250px; // Диаметр центрального круга
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border: 2px solid white;
      opacity: 0.5; 
      z-index: 2;// Цвет центрального круга
    }
    .line {
        width: 100%;
        height: 50px;
        position: absolute;
        left: 0px;
        background-color: green;
        opacity: 0.6;
    }
    .field_top {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        width: 100%;
        justify-content: space-between;
        z-index: 2;
    }
    .corner {
        width: 40px;
        height: 40px;
    } 
    .penalty_area {
        width: 260px;
        height: 100px;
        border: 1px solid white;
        border-top: none;
        z-index: 2;
        position: relative;
        &_dop {
            position: absolute;
            top: 100px;
            left: 50%;
            width: 60px;
            transform: translate(-50%);
            height: 20px; /* Высота половины круга *//* Цвет фона круга */
            border-bottom-left-radius: 30px; /* Закругление левой части */
            border-bottom-right-radius: 30px; 
            border: 1px solid white;
        }
    }
    .field_bottom {
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        width: 100%;
        justify-content: space-between;
        transform: rotate(180deg);
        z-index: 2;
    }
    .team_schema {
        z-index: 3;
    }
    .goalkeeper {
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .defending {
        display: flex;
        justify-content: space-between;
        padding: 30px;
        position: absolute;
        width: 100%;
        bottom: 130px;
        left: 0;
    }
    .player_ball {
        width: 50px;
        height: 50px;
        border: 3px solid white;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: black;
        &:hover {
            background-color: $active-el-color;
            color: $championship-table-team-progress-bar-light;
        }
    }
    .midfield {
        padding: 20px;
        position: absolute;
        width: 100%;
        bottom: 160px;
        display: flex;
        justify-content: space-between;
    }
  }
  