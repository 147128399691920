@import '../../../styles/variables.scss';


.params_select {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding: 12px 8px;
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: white;
    cursor: pointer;
    .img_wrapper {
        width: 40px;
        height: 40px;
        @media (max-width: 576px) {
            width: 20px;
            height: 20px;
        }
        & > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .chart_select {
        position: absolute;
        top: 40px;
        padding: 5px;
        left: 0;
        // background: #181818;
        box-shadow: 0px 2px 7px rgba(31, 31, 34, 0.25);
        border-radius: 8px;
        color: white;
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;
        z-index: 4;
        max-height: 200px;
        overflow-y: auto;
        background: #111;
        background: drop-shadow(0px 2px 7px rgba(31, 31, 34, 0.25));
        max-height: 0; /* Начальное состояние: скрыто */
        opacity: 0; /* Начальная прозрачность */
        overflow: hidden; /* Скрываем содержимое при закрытом меню */
        transition: max-height 0.3s ease-out, opacity 0.3s ease-out; /* Плавные переходы */
        &.chart_select_open {
            max-height: 200px; /* Конечная максимальная высота при открытии */
            opacity: 1; /* Видимость при открытии */
            overflow-y: auto; /* Включаем прокрутку при необходимости */
        }
    }
    .selectors_list {
        @media (max-width: 576px) {
            display: flex;
            flex-direction: column;
            gap: 15px;
        }
    }
    .select_metric {
        white-space: nowrap;
        padding: 5px;
        display: flex;
        align-items: center;
        gap: 10px;
        & >:nth-child(1) {
          width: 5px;
        }
        &:hover {
          background: rgba(255, 255, 255, 0.1);
          border-radius: 4px;
          cursor: pointer;
        }
        
    } 
    .img_wrapper {
        min-width: 40px;
        height: 40px;
        @media (max-width: 576px) {
			min-width: 20px;
			height: 20px;
		}
        & > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}






