.chart_header {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 12px;
	padding: 10px;

	@media (max-width: 576px) {
		// display: none;
		padding: 5px;
		height: 35px;
	}
	@media (min-width: 576px) and (orientation: landscape) and (max-width: 768px) {
		padding: 5px;
		height: 35px;
	}

	.params_wrapper {
		display: flex;
		gap: 10px;
		align-items: center;
		font-size: 12px;
		@media (max-width: 576px) {
			gap: 5px;
			font-size: 10px;
		}
		@media (min-width: 576px) and (orientation: landscape) and (max-width: 768px) {
			gap: 5px;
			font-size: 10px;
		}
		& > span {
			@media (max-width: 576px) {
				display: none
			}
		}
	}

	.candle_params {
		display: flex;
		gap: 10px;
		align-items: center;
		flex-wrap: wrap;
		@media (min-width: 576px) and (orientation: landscape) and (max-width: 768px) {
			gap: 5px;
		}

	}

	.divider {
		width: 1px;
		height: 20px;
		background: white;
		@media (max-width: 576px) {
			display: none
		}
	}

	.img_wrapper {
		width: 25px;
		height: 25px;

		&>img {
			width: 100%;
			height: 100%;
		}
		@media (max-width: 576px) {
			display: none
		}
		
	}

	.candle_team_params {
		display: flex;
		gap: 5px;
	}

	.candle_win {
		color: #ED392F;
	}

	.candle_lose {
		color: #469A1F;
	}

	.candle_draw {
		color: #EFFF3C;
	}

	.expand {
		border: none;
		background-color: transparent;
		cursor: pointer;
		width: 25px;
		height: 25px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.light {
	color: black;
}

.dark {
	color: white;
}