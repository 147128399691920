@import '../../styles/variables';

.single_cup_team_achievement {
	display: flex;
	justify-content: space-between;
	align-items: center;
	
	padding-bottom: 12px;
	.border_dark {
		border-bottom: 1px solid $substrate-color;
	}
	.border_light {
		border-bottom: 1px solid $substrate-color-light;
	}
	@media (min-width: 320px) and (max-width: 576px) {
		border-bottom: none;
		background: #181818;
		padding: 10px;
		flex-direction: column;
		border-radius: 10px;
	}

	.cup_description {
		display: flex;
		gap: 10px;
		align-items: center;
		width: 25%;

		@media (min-width: 320px) and (max-width: 576px) {
			width: 100%;
		}
	}

	.achievement_border {
		background: #1F1F1F;
		width: 100%;
		height: 2px;
		display: none;

		@media (min-width: 320px) and (max-width: 576px) {
			display: block;
		}
	}

	.cup_img_container {
		min-width: 64px;
		min-height: 64px;
		background: #181818;
		border-radius: 4px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.cup_img_wrapper {
		width: 38px;
		height: 38px;

		&>img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.cup_title {
		font-weight: 400;
		font-size: 14px;
		line-height: 18px;
		color: white;
		max-width: 120px;
	}

	.titles_quantities {
		display: flex;
		gap: 10px;
		width: 100%;
		justify-content: space-between;
		align-items: center
	}

	.games {
		font-weight: 400;
		font-size: 14px;
		line-height: 18px;
		color: #FFFFFF;
		width: 100%;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		@media (min-width: 320px) and (max-width: 576px) {
			font-size: 12px;
			display: flex;
			align-items: flex-start;
		}
	}

	.cup {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		color: white;
		font-weight: 400;
		font-size: 18px;
		line-height: 18px;
		@media (min-width: 320px) and (max-width: 576px) {
			width: 20px;
			height: 20px;
			font-size: 12px;
		}
	}

	.metric_type {
		display: none;

		@media (max-width: 576px) {
			display: flex;
			opacity: 0.5;
			white-space: nowrap;
			
		}
	}
	.metrics {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		padding: 5px;
		gap: 5px;
		width: 75%;
		@media (min-width: 320px) and (max-width: 576px) {
			max-width: 100%;
			margin-top: 10px;
		}
	}
	.metric_item {
		width: 25%;
		display: flex;
		height: 50px;
		@media (min-width: 320px) and (max-width: 576px) {
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			gap: 10px;
			font-size: 12px;
			width: auto;
		}
	}
}