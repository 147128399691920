@import '../../styles/variables.scss';


@import '../../styles/variables.scss';

.container {
  width: 100%;
  color: $main-color;
  -webkit-transition: color .3s ease;
  transition: color .3s ease;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  user-select: none;
  width: 100%;
}

.notstarted_games {
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
}

.title {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  user-select: none;
  color: white;
  cursor: pointer;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;


}

.recent_result_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 9px;
  gap: 10px;
  max-width: 100%;
  overflow: hidden;
  width: 100%;
  min-height: 60px;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    cursor: pointer;
  }
}

.team {
  display: flex;
  align-items: center;
  gap: 5px;

  &>span {
    width: 90px;
  }

}
.team_logo {
  display: block;
  height: 40px;
  width: 40px;
  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media (max-width: 576px) {
    height: 30px;
    width: 30px;
  }
}

.home_team_name {
  font-size: 14px;
  text-align: left;

  // white-space: nowrap;
  @media (max-width: 1300px) {
    display: none !important;
  }
  @media (max-width: 768px) {
    display: block !important;
  }
  @media (max-width: 576px) {
    display: none !important;
  }
}

.away_team_name {
  font-size: 14px;
  text-align: right;

  // white-space: nowrap;
  @media (max-width: 1300px) {
    display: none !important;
  }
  @media (max-width: 768px) {
    display: block !important;
  }
  @media (max-width: 576px) {
    display: none !important;
  }
}

.total_goals {
  background: #333333;
  border-radius: 4px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  padding: 4px 9px;
  width: 60px;
  height: 40px;
  font-size: 14px;
}
.total_goals_light {
  background: white;
  border: 1px solid #CBC9CC;
  border-radius: 4px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  padding: 4px 9px;
  width: 60px;
  height: 40px;
}
.detail_match_info_wrapper {
  background-color: #181818;
  border-radius: 4px;
  padding: 12px;
  opacity: 1;
  transition: 0.3s;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.date {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white
}
.detail_match_info {
  display: flex;
  justify-content: space-between;
  
}

.goal_section_home {
  width: 30%;
  display: flex;
  flex-direction: column;
  color: white;
  gap: 5px;
  align-items: flex-start;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
}

.goal_section_away {
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: white;
  align-items: flex-end;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
}

.ball_img_wrapper {
  width: 20px;
  height: 20px;

  &>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.collapsible {
  max-height: 0;
  overflow: hidden;
  transition: 0.3s;
  color: #282D3C;
}

// .blockradius {
//   border-radius: 24px;
//   overflow: hidden;
// }

.light {
  color: $text-color-light
}

.dark {
  color: $main-color
}