@import '../../../styles/variables.scss';

.tab_matches_list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background-color: $bg-color;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  .tab_match {
    display: flex;
    flex-direction: column;
    background-color: $bg-rectangle-color;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s;
  
  }
  
  .tab_match_details {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
  }
  
  .tab_match_img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    flex-shrink: 0;
  }
  
  .tab_match_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .tab_match_details div {
    font-size: 16px;
    color: $main-color;
  }
  
  .tab_match_details div:last-child {
    font-weight: bold;
    color: $active-el-color;
  }
  .team_name {
    width: 120px;
  }
}


