.stadium_description {
    margin-top: 6px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;
    overflow-y: auto;
    @media (max-width: 756px) {
        display: none;
    }
}

.description_text {
    color: white;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.stadium_img {
    width: 200px;
    height: 100px;
    
    & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 5px;
    }
}