.news_modal_background {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.74);
    top: 0;
    left: 0;
    z-index: 4;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    z-index: 1000;
    .modal_content {
        width: 40%;
        max-height: 800px;
        background-color: white;
        padding: 20px 20px;
        overflow-y: auto;
        @media (max-width: 576px) {
            width: 100%;
            max-height: 600px;
        }
    }
    .btns_header {
        display: flex;
        gap: 15px;
    }
    .modal_header {
        display: flex;
        width: 100%;
        justify-content: space-between;
        color: black;
        align-items: center;
    }
    .header_text {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        color: black;
    }
    .news_info {
        display: flex;
        gap: 10px;
    }
    .close_btn {
        cursor: pointer;
    }
    .img_wrapper {
        margin-top: 30px;
        width: 100%;
        height: 100%;
        & > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .text {
        color: black;
        margin-top: 30px;
        // overflow-y: auto;
        white-space: pre-line;
        overflow-y: auto;
    }
}