@import "../../styles/variables.scss";

.players_records {
	display: flex;
	flex-direction: column;
	overflow: auto;
	height: 100%;
	@media (max-width: 576px) {
		padding: 10px
	}

	.players_records_header {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 10px;
		border-bottom: 1px solid $substrate-color;
		@media (max-width: 576px) {
			justify-content: flex-start;
			border-bottom: none;
		}
	}
	.players_records_subheader {
		display: flex;
		justify-content: space-between;
		width: 100%;
		@media (max-width: 576px) {
			display: none
		}
	}
	.subheader_item {
		width: calc(100% / 5);
		padding: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-bottom: 1px solid $substrate-color;
		&:not(:last-child) {
			border-right: 1px solid $substrate-color;
		}
	}
	.records_list {
		@media (max-width: 576px) {
			display: flex;
			flex-direction: column;
			gap: 15px;
		}
	}
}