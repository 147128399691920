.tooltip_left {
  // position: absolute;
  background: #FFFFFF;
  border-radius: 7px;
  padding: 6px 12px 6px 12px;
  // min-width: 150px;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  white-space: nowrap;
  color: #1F1F1F;
  transition: 0.5s;
  opacity: 0;
  position: fixed;
  transform: translateX(-10px);
  pointer-events: none;
  box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.50);
}
.tooltip_right {
  // position: absolute;
  background: #FFFFFF;
  border-radius: 7px;
  padding: 6px 12px 6px 12px;
  // min-width: 150px;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em; 
  white-space: nowrap;
  color: #1F1F1F;
  position: relative;
  transition: 0.5s;
  opacity: 0;
  position: absolute;
  transform: translateX(40px);
  box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.50);
  //display: none;
  // &:after {
  //   content: "";
  //   position: absolute;
  //   bottom: -33%;
  //   left: 50%;
  //   margin-left: -5px;
  //   border-width: 5px;
  //   border-style: solid;
  //   border-color: transparent transparent white transparent;
  //   transform: rotate(180deg);
  // }
}

.visible_false {
  opacity: 0;
  // transform: translateX(-10px);
}

.position_left {
  // position: absolute;
  // transform: translateX(-10px);
  opacity: 1;
}

.position_right {
  // position: absolute;
  transform: translateX(40px);
  opacity: 1;
}

