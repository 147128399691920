@import '../../styles/variables.scss';
.team_defail_info_section {
    display: flex;
    width: 100%;
    @media (max-width: 756px) {
        flex-direction: row-reverse;
    }
    @media (max-width: 576px) {
        
    }
    .panel_section {
        min-width: 53px;
        max-width: 53px;
        border-left: 1px solid $substrate-color;
        padding: 7px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 3px;
        position: relative;
        overflow-y: auto;
        @media (max-width: 756px) {
            border-left: none;
            border-right: 1px solid $substrate-color;
            padding-bottom: 60px;
        }
        & > ::-webkit-scrollbar {
            width: 0;
            background-color: transparent;
        }
    }
    .components_content {
        width: calc( 100% - 53px);
        @media (max-width: 576px) {
            width: calc(100% - 51px);
        }
    }
}

.panel_section::-webkit-scrollbar {
    width: 0;
    background-color: transparent;
}

