@import '../../styles/variables.scss';

.midfield_wrapper {
    width: calc(100% / 3);
    .midfield_header {
        height: 20%;
        border-right: 1px solid $substrate-color;
        font-size: 14px;
        border-bottom: 1px solid $substrate-color;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .midfield {
        width: 100%;
        height: 80%;
        border-right: 1px solid #5C5C5C;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 10px;
        position: relative;
    }
    .midfield_active_option {
        font-size: 12px;
        position: absolute;
        top: 15%;
    }
    .circle {
        width: 84px;
        height: 84px;
        border-radius: 50%;
        background-color: transparent;
        border: 6px solid #DC7700;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
}