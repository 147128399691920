@import '../../../styles/variables.scss';

.control_panel_item {
	background: transparent;
	list-style: none;
	width: 36px;
	height: 36px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border: none;

	//border: 1px solid red;
	&:hover {
		background: #181818;
		border-radius: 4px;
	}

	.item_logo {
		width: 22px;
		height: 22px;

		&>img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}
}

.control_panel_item_light {
	border-radius: 4px;
	list-style: none;
	width: 36px;
	height: 36px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border: none;

	&:hover {
		background-color: #F5F5F5;
	}
}

.contol_panel_item_active_light {
	border-radius: 4px;
	list-style: none;
	width: 36px;
	height: 36px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border: none
}


.active {
	background: #181818;
	border-radius: 4px;
	list-style: none;
	width: 36px;
	height: 36px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border: none
}

.selection_elements {
	position: absolute;
	background: #181818;
	width: 200px;
	height: auto; /* Убираем фиксированную высоту */
	right: 45px;
	top: 0;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.5),
		0 0 10px rgba(0, 0, 0, 0.5),
		0 0 10px rgba(0, 0, 0, 0.5),
		0 0 10px rgba(0, 0, 0, 0.5);
	border-radius: 8px;
	color: white;
	display: flex;
	flex-direction: column;
	gap: 10px;
	overflow: hidden; /* Скрываем содержимое, пока меню скрыто */
	transition: max-height 0.3s ease-out, opacity 0.3s ease-out; /* Переход для max-height и прозрачности */
	opacity: 0; /* Начальная прозрачность */
	max-height: 0; /* Начальное состояние для max-height */
	@media (max-width: 576px) {
		z-index: 4;
		padding: 5px;
		gap: 5px;
		font-size: 12px;
	}

	.select_metric {
		white-space: nowrap;
		padding: 10px;
		display: flex;
		align-items: center;
		gap: 10px;

		@media (max-width: 576px) {
			padding: 5px;
		}

		&>:nth-child(1) {
			width: 5px;
		}

		&:hover {
			background: rgba(255, 255, 255, 0.1);
			border-radius: 4px;
			cursor: pointer;
		}
	}
	.active_indicator {
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background-color: $active-el-color;

		@media (max-width: 576px) {
			width: 5px;
			height: 5px;
		}
	}
}

.open {
	padding: 10px;
	max-height: 200px; /* Конечная максимальная высота */
	opacity: 1; /* Видимость при открытии */
}
