@import '../../styles/variables';

.team_stat {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	// overflow-y: auto;
	.stat_header {
		display: flex;
		height: 80px;
		@media (max-width: 576px) {
			width: 100%;
			height: auto;
		}
	}
	.team {
		padding: 6px 16px;
		display: flex;
		align-items: center;
		gap: 10px;
		font-weight: 400;
		font-size: 14px;
		line-height: 16px;
		color: white;
		width: 30%;
		border-bottom: 1px solid $substrate-color;
		position: relative;
		@media (max-width: 576px) {
			width: 50%;
			border-right: 1px solid $substrate-color;
			padding: 10px;
			border-top: 1px solid $substrate-color;
		}
	}

	.params {
		width: 40%;
		border-bottom: 1px solid $substrate-color;
		border-right: 1px solid $substrate-color;
		border-left: 1px solid $substrate-color;
		@media (max-width: 576px) {
			display: none;
		}
	}
	.params_mobile {
		display: none; 
		@media (max-width: 576px) {
			display: flex;
		}
	}

	.times {
		width: 100%;
		display: flex;
		height: 50%;
		@media (max-width: 576px) {
			height: 100%;
			width: 50%;
		}
	}

	.time_item {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 12px 8px;
		width: 25%;
		font-weight: 400;
		font-size: 14px;
		line-height: 16px;
		color: grey;
		border-bottom: 1px solid $substrate-color;
		cursor: pointer;
	}

	.time_item:not(:last-child) {
		border-right: 1px solid $substrate-color;
	}

	.params_controller {
		padding: 12px 8px;
		display: flex;
		justify-content: space-between;
		height: 50%;
		font-weight: 400;
		font-size: 14px;
		line-height: 16px;
		color: white;
		@media (max-width: 576px) {
			width: 50%;
			height: 100%;
		}
	}

	.img_wrapper {
		width: 40px;
		height: 40px;
		@media (max-width: 576px) {
			width: 20px;
			height: 20px;
		}
		& > img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.stat_metrics {
		width: 100%;
		height: 100%;
		overflow-y: auto;
	}

	.param_metric {
		min-height: 30px;
		width: 100%;
		display: flex;
		border-bottom: 1px solid $substrate-color;
	}

	.param_metric_stats {
		display: flex;
		width: 40%;
		text-align: center;
		border-right: 1px solid $substrate-color;
		border-left: 1px solid $substrate-color;
		justify-content: space-between;
	}

	.chart {
		width: 30%;
		display: flex;
		padding: 12px;
		align-items: center;
	}

	.param_item {
		padding: 19px 12px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: 400;
		font-size: 14px;
		line-height: 16px;
		color: white;
		width: 33.3%;
	}

	.param_item:not(:last-child) {
		border-right: 1px solid $substrate-color;
	}

	.possible_metric {
		position: relative;
		width: 100%;
		height: 10px;
		border-radius: 20px;
		background-color: transparent;
		border: 1px solid #5C5C5C;
	}
	.chart_left {
		height: 10px;
		border-radius: 20px;
		background-color: red;
		position: absolute;
	}

	.active_selector {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 12px 8px;
		width: 25%;
		font-weight: 400;
		font-size: 14px;
		line-height: 16px;
		border-bottom: 1px solid $substrate-color;
		cursor: pointer;
		background-color: $active-el-color;
		color: $main-color;
	}
}

.stat_metrics {
	overflow-y: scroll; /* или auto */
	scrollbar-width: thin; /* или auto */
	scrollbar-color: transparent transparent; /* или указать цвет фона и цвет ползунка */
	position: relative;
}

.stat_metrics::-webkit-scrollbar {
	width: 0px; /* или другая ширина ползунка */
}

.stat_metrics::-webkit-scrollbar-thumb {
	background-color: transparent; /* или указать цвет ползунка */
}

.stat_metrics::-webkit-scrollbar-track {
	background-color: transparent; /* или указать цвет фона */
	border-radius: 3px; /* или другие стили рамки */
}

.stat_metrics::before {
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	width: 0px; /* или другая ширина ползунка */
	background-color: #fff; /* или указать цвет фона */
}